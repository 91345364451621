import React, { useEffect, useState } from "react";
import DeleteIcon from "../../assets/images/delete.svg"
import EditIcon from "../../assets/images/edit.svg"
import { apiDELETE, apiGET,jobsApiPATCH } from "../../utils/api";
import DataTable from "../../components/dataTable/DataTable";
import { Stack, IconButton } from "@mui/material";
import img1 from "../../assets/images/blogger.png";
import { useNavigate } from "react-router-dom";
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";

export default function BlogsTable() {
  const [blogs, setBlogs] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [filerBlogs, setFilerBlogs] = useState("");
  const navigate = useNavigate();
  const [statusChanged, setStatusChanged] = useState(false);

  // const handleStatusChange = (event, params) => {

  // }
  const handleStatusChange = (event, params) => {
    const { _id } = params.row.original;
    var obj = {};
    if (params.row.original.status) {
      obj = { status: false };

      jobsApiPATCH("blog/update_status", obj, _id)
        .then((response) => {
          params.row.original.status = false;
          getBlogsList();
          if (response.data.error) {
            console.log(response.data.error_message)
            console.log("1")
          } else {
            console.log("2", params.row.original.status)
          }
        })
        .catch((err) => {
          console.log(err);

        });
    } else {
      obj = { status: true }
      jobsApiPATCH("blog/update_status", obj, _id)
        .then((response) => {
          params.row.original.status = false;
          getBlogsList();
          if (response.data.error) {
            console.log(response.data.error_message)
            console.log("3")
          } else {
            params.row.original.status = true;
            console.log("4")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

 





  const handleDelete = (id) => {
    apiDELETE("blog", id)
      .then((res) => {
        console.log(res.data.message);
        if (res.data.status === true) {
          setStatusMessage(res.data.message);

          const posts = filerBlogs.filter((item) => item._id !== id);
          setFilerBlogs(posts);
          setTimeout(() => {
            setStatusMessage("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = (row) => {
    navigate("/blogs/updateblog", { state: { job: row } });
  };

  useEffect(() => {
    getBlogsList();
  }, []);

  const getBlogsList = () => {
    apiGET("blog/get_blogs")
      .then((res) => {
        setBlogs(res.data.blogs);
        setFilerBlogs(res.data.blogs);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const rows = [
    { id: 1, date: '2020/02/11 16:26', status: 'Category A' },
    { id: 2, name: 'Item 2', category: 'Category B' },
    { id: 3, name: 'Item 3', category: 'Category A' },
    // Add more data rows as needed
  ];

  const blogsColumns = [
    {
      // accessorFn for fetching multiple accessorKeys
      accessorFn: (row) => `${row.title} ${row.image}`,
      header: "Title Posts",
      size: 372.5,
      Cell: (params) => {
        return (
          <div className="" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className="" >
              <img src={params.row.original.image} alt='blog' style={{ width: '69px', height: '44px', borderRadius: '10px' }} />
            </div>
            <div className="" style={{ paddingLeft: '20px' }}>
              {params.row.original.title}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "createdAt",
      header: "Date & Time",
      size: 372.5,
      Cell: (params) => {
        const timeString = params.renderedCellValue;
        const dateTime = new Date(timeString);

        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1; // Months are zero-based, so adding 1
        const date = dateTime.getDate();
        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        return (
          <div style={{ color: '#939393' }}>
            {`${year}/${month}/${date} ${hours}:${minutes}`}
          </div>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 160,
      Cell: (params) => {
        console.log("jobs Status Params", params)
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {params.row.original.status === true ? (
              <div className={`cell-with-status active`}>{"Published"}</div>
            ) : (
              <div className={`cell-with-status passive`}>{"Archive"}</div>
            )}

            <FormGroup style={{ paddingLeft: '20px' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={params.row.original.status}
                    color="primary"
                    onChange={(event) => {
                      handleStatusChange(event, params)
                    }}
                  />
                }
              />
            </FormGroup>
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 372.5,
      Cell: (params) => {
        return (
          <div className="cell-action">
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={() => handleDelete(params.row.original._id)}
            >

              <img src={DeleteIcon} alt="delete" width="17px" height="22.17px" />
            </IconButton>

            <IconButton
              aria-label="edit"
              size="medium"
              onClick={() => handleUpdate(params.row.original)}
            >
              <img src={EditIcon} alt="delete" width="21px" height="21.98px" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <DataTable
        data={blogs}
        columns={blogsColumns}
        rows={rows}
      />
    </>
  );
}
