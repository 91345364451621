import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  IconButton,
} from "@mui/material";

import { Edit } from "@mui/icons-material";
import axios from "axios";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { apiDELETE } from "../../utils/api";
import DataTable from "../../components/dataTable/DataTable";
import SnackBar from "../../components/alert/SnackBar";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import CancelIcon from "@mui/icons-material/Cancel";
import pdf from "../../assets/images/pdf.png"
import {
  GET_APPLIED_JOBS_LIST,
  UPDATE_APPLIED_JOB_ACCEPTED_STATUS_BY_ID,
  UPDATE_APPLIED_JOB_REVIEWED_STATUS_BY_ID,
} from "../../utils/config";
import DeleteIcon from "../../assets/images/delete.svg";
import menuIcon from "../../assets/images/menu-icon.png"

export default function AppliedJobsTable() {
  const [jobs, setJobs] = useState([]);
  const [action, setAction] = React.useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  //const[rows , setRows]=useState("");
  //delete

  const [dataId,setDataId] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState('Accepted');
  const options = ['Reviewed', 'Pre Screening', 'Accepted', 'Rejected'];

  const handleClick = (event,id) => {
    setDataId(id)
    setAnchorEl(event.currentTarget);
  };

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async(option,event) => {
    // setSelectedOption(option);
    handleClosed();

    try{
      const response = await axios.patch(
        UPDATE_APPLIED_JOB_ACCEPTED_STATUS_BY_ID + dataId,
        { status: option }
      );

      const { error, error_message, success_message } = response.data;

      if (error) {
        setOpen(true);
        setMessage(error_message);
        setSeverity("error");
      } else {
        getJobsList();
        setOpen(true);
        setMessage(success_message);
        setSeverity("success");
      }
      

    }catch(err){
      setOpen(true);
      setMessage("Error: " + err.message);
      setSeverity("error");
    }
  };

  const handleDelete = (id) => {
    console.log("id",id)
    apiDELETE("apply_job", id)
      .then((res) => {
        if (res.data.status === true) {
          setStatusMessage(res.data.message);
          alert("Updated Job successfully")
          setTimeout(() => {
            setStatusMessage("");
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //handlechange for status
  const handleChange = async (event, id, accepted) => {
    try {
      const updatedRows = jobs.map((row) => {
        if (row._id === id) {
          return {
            ...row,
            is_accepted: accepted,
            review: accepted ? "Accepted" : "Rejected",
          };
        }
        return row;
      });

      setJobs(updatedRows);

      const response = await axios.patch(
        UPDATE_APPLIED_JOB_ACCEPTED_STATUS_BY_ID + id,
        { is_accepted: accepted }
      );

      const { error, error_message, success_message } = response.data;

      if (error) {
        setOpen(true);
        setMessage(error_message);
        setSeverity("error");
      } else {
        setOpen(true);
        setMessage(success_message);
        setSeverity("success");
      }
    } catch (err) {
      setOpen(true);
      setMessage("Error: " + err.message);
      setSeverity("error");
    }
  };

  const [statusMessage, setStatusMessage] = useState("");

  useEffect(() => {
    getJobsList();
  }, []);

  const getJobsList = () => {
    axios
      .get(GET_APPLIED_JOBS_LIST)
      .then((response) => {
        setJobs(response.data.applied_jobs);
        console.log("data", response.data);
      })

      .catch((err) => {
        setOpen(true);
        setMessage("error: " + err);
        setSeverity("error");
      });
  };

  const handleReviewChange = (event, id) => {
    axios
      .patch(UPDATE_APPLIED_JOB_REVIEWED_STATUS_BY_ID + id, {
        is_reviewed: event.target.checked,
      })
      .then((res) => {
        alert("Updated Job successfully");
      })
      .catch((err) => {
        setOpen(true);
        setMessage("error: " + err);
        setSeverity("error");
      });
  };
  const appliedJobsColumns = [
    {
      accessorFn: (row) => `${row.first_name} ${row.last_name}`,
      header: "Applicant Name",
      size: 230,
      Cell: (params) => {
        return <div>{params.row.original.first_name && params.row.original.last_name ? (
          params.row.original.first_name + " " + params.row.original.last_name
        ) : (
          params.row.original.first_name || params.row.original.last_name || " "
        )}</div>;
      },
    },
    {
      accessorKey: "email",
      header: "Email Address",
      size: 230,
    },
    {
      accessorKey: "job.job_title",
      header: "Job-Title",
      size: 230,
      Cell: (params) => {
        return <div>{params.renderedCellValue}</div>;
      },
    },

    {
      accessorKey: "job.position",
      header: "Position",
      size: 200,
      // marginleft:50,
      Cell: (params) => {
        return <div>{params.renderedCellValue}</div>;
      },
    },
    {
      accessorKey: "createdAt",
      header: "Date",
      size: 200,
      // marginleft:50,
      Cell: (params) => {
        const timeString = params.row.original.createdAt;
        const dateTime = new Date(timeString);

        const year = dateTime.getFullYear();
        const month = dateTime.getMonth() + 1; // Months are zero-based, so adding 1
        const date = dateTime.getDate();
        const hours = dateTime.getHours();
        const minutes = dateTime.getMinutes();
        return (
          <div style={{ color: '#939393' }}>
            {`${date}/${month}/${year}`}
          </div>
        );
      },
    },
    {
      accessorKey: "file_path",
      header: "Resume",
      size: 100,
      Cell: (params) => {
        const url = params.renderedCellValue;
        const fileType = url.substring(url.lastIndexOf('.') + 1);
        return (
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Stack><a href={params.row.original.file_path} target="_blank" download={params.row.original.file_path}><img src={pdf} alt="" /></a></Stack>
            <Stack sx={{ paddingLeft: '4px', fontSize: '18px' }}>{`.${fileType}`}</Stack>
          </Stack>
        );
      },
    },
    // {
    //   accessorKey: "status",
    //   header: "Read Status",
    //   size: 100,

    //   Cell: (params) => {
    //     return (
    //       <Box
    //         display={"flex"}
    //         justifyContent="space-between"
    //         columnGap={"10px"}
    //       >
    //         <FormControlLabel
    //           label={params.row?.isReviewed ? "Seen" : "Pending"}
    //           control={
    //             <Checkbox
    //               checked={params.row?.is_reviewed}
    //               color="secondary"
    //               onChange={(e) => handleReviewChange(e, params.row?._id)}
    //             />
    //           }
    //         />
    //       </Box>
    //     );
    //   },
    // },

    {
      accessorKey: "Job status",
      header: "Job Status",
      size: 180,
      Cell: (params) => {
        return (
          
              <>
                <div className={`applied-job-status ${params.row.original.status === 'Accepted' ? 'accepted' : params.row.original.status === 'Rejected' ? 'rejected' : params.row.original.status === 'Pre Screening' ? 'pre-screening' : params.row.original.status === 'Reviewed' ? 'reviewed' :  'active'}`} onClick={(event)=>handleClick(event,params.row.original._id)}>
                  {params.row.original.status }
                  <img src={menuIcon} alt='' />
                </div>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClosed}
                  sx={{
                    '& .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper ':{
                      boxShadow:'0px 0px 0px 0.1px rgba(0, 0, 0, 0.3);',
                      borderRadius:'6px'
                    }
                  }}
                >
                  {options.map((option, index) => (
                    <MenuItem  key={index} onClick={(event) => handleMenuItemClick(option,event)}>
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </>
        );
      },
    },
    // {
    //   accessorKey: "Job Response",
    //   header: "Job Response",
    //   size: 180,

    //   Cell: (params) => {
    //     return (
    //       <>
    //         {params.row.original.is_accepted === true ? (
    //           <div className={`cell-with-status active`}>
    //             <IconButton
    //               aria-label="rejected"
    //               size="medium"
    //               onClick={(event) =>
    //                 handleChange(event, params.row.original._id, false)
    //               }
    //             >
    //               <CancelIcon fontSize="inherit" />
    //             </IconButton>
    //           </div>
    //         ) : (
    //           <div className={`cell-with-status active`}>
    //             <IconButton
    //               aria-label="accepted"
    //               size="medium"
    //               onClick={(event) => handleChange(event, params.row.original._id, true)}
    //             >
    //               <HowToRegIcon fontSize="inherit" />
    //             </IconButton>
    //           </div>
    //         )}
    //       </>
    //     );
    //   },
    // },
    {
      accessorKey: "action",
      header: "Action",
      size: 150,
      Cell: (params) => {
        return (
          <div className="cell-action">
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={() => handleDelete(params.row.original._id)}
            >
              <img src={DeleteIcon} alt="delete" width="21px" height="21.98px" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <DataTable data={jobs} columns={appliedJobsColumns} />

      <SnackBar
        open={open}
        severity={severity}
        message={message}
        handleClose={handleClose}
      />
    </>
  );
}
