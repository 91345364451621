import React, { useEffect, useState } from "react";
import axios from "axios";
import { IconButton } from "@mui/material";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Button } from "@mui/material";

// import "./styles.scss";
import DataTable from "../../components/dataTable/DataTable";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/Navbar";

import {
  DELETE_EMPLOYEE_BY_ID,
  GET_EMPLOYEES_LIST,
  UPDATE_EMPLOYEE_BY_ID,
} from "../../utils/config";
import ListHeader from "../../components/listHeader/ListHeader";
import SnackBar from "../../components/alert/SnackBar";
import Popup from "../../components/popup/Popup";
import DeleteIcon from "../../assets/images/delete.svg"
import EditIcon from "../../assets/images/edit.svg"

export default function GetEmployeesList() {
  const [data, setData] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [id, setId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  var employee = {
    first_name: "",
    last_name: "",
    phone: "",
    address: "",
    email: "",
  };
  useEffect(() => {
    getEmployeesList();
  },[]);
  

  const employeeColumn = [
    {
      accessorKey: "first_name",
      header: "First Name",
      size: 230,
    },
    {
      accessorKey: "last_name",
      header: "Last Name",
      size: 230,
    },
    {
      accessorKey: "phone_number",
      header: "Phone ",
      size: 230,
    },
    {
      accessorKey: "email",
      header: "Email Address",
      size: 230,
    },
    {
      accessorKey: "address",
      header: "Address",
      size: 230,
    },

    {
      accessorKey: "action",
      header: "Action",
      width: 250,
      Cell: (params) => {
        return (
          <div className="cell-action">
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={() => deleteEmployee(params.row.original._id)}
            >
              <img src={DeleteIcon} alt="delete" width="17px" height="22.17px" />
            </IconButton>
            <IconButton
              aria-label="edit"
              size="medium"
              onClick={() => editEmployee(params.row.original)}
            >
              <img src={EditIcon} alt="delete" width="21px" height="21.98px" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const getEmployeesList = () => {
    console.log(GET_EMPLOYEES_LIST)
    axios
      .get(GET_EMPLOYEES_LIST)
      .then(function (response) {
        if (response.data.error) {
          setOpen(true);
          setMessage(response.data.error_msg);
          setSeverity("error");
        } else {
          setData(response.data.employees);
        }
      })
      .catch(function (error) {
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };
  const deleteEmployee = (id) => {
    axios
      .delete(DELETE_EMPLOYEE_BY_ID + id)
      .then(function (response) {
        if (response.data.error) {
          setOpen(true);
          setMessage(response.data.error_msg);
          setSeverity("error");
        } else {
          setOpen(true);
          setMessage(response.data.success_msg);
          setSeverity("success");
        }
      })
      .catch(function (error) {
        setOpen(true);
        setMessage(error);
        setSeverity("error");
      });
  };
  const updateEmployee = () => {
    employee = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      address: address,
      email: email,
    };
    axios
      .patch(UPDATE_EMPLOYEE_BY_ID + id, employee)
      .then(function (response) {
        if (response.data.error) {
          setOpen(true);
          setMessage(response.data.error_msg);
          setSeverity("error");
        } else {
          setOpen(true);
          setMessage(response.data.success_msg);
          setSeverity("success");
          setOpenPopup(false);
          setId("");
          setFirstName("");
          setLastName("");
          setPhone("");
          setAddress("");
          setEmail("");
        }
      })
      .catch(function (error) {
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };

  const validation = () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      phone.length === 0 ||
      address.length === 0 ||
      email.length === 0
    ) {
      setOpen(true);
      setMessage("Some fields are missing");
      setSeverity("error");
    } else {
      updateEmployee();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const editEmployee = (employee) => {
    setOpenPopup(true);
    setId(employee._id);
    setFirstName(employee.first_name);
    setLastName(employee.last_name);
    setPhone(employee.phone_number);
    setAddress(employee.address);
    setEmail(employee.email);
  };
  return (
    <div className="list">
      <Sidebar />
      <div className="list-container">
        <Navbar header={"Employee"} />
        <ListHeader
            header={"Dashboard"}
            header1={"Employee"}
            firstLinks={"/"}
            secondLinks={"/employee"}
            firstButton={true}
            firstButtonText={"Add New Employee"}
            firstLink={"/employee/add"}
        />
        <DataTable
          data={data}
          columns={employeeColumn}
          // loading={!data.length}
          isForTransaction={false}
        />
        <Popup
          title="Employee Form"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="First Name"
                fullWidth
                variant="outlined"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Last Name"
                fullWidth
                variant="outlined"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Phone"
                fullWidth
                variant="outlined"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="address"
                name="address"
                label="Address"
                fullWidth
                variant="outlined"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                justifyContent={"flex-end"}
                container
                spacing={1}
                direction={"row"}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    size="medium"
                    sx={{ textTransform: 'none', width: '79px', height: '45px', background: '#2D74FF', color: '#FFFFFF', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontFamily: 'Plus Jakarta Sans', }}
                    onClick={() => validation()}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button 
                  onClick={()=>setOpenPopup(false)}
                  size="medium" 
                  sx={{ textTransform: 'none', width: '97px', height: '45px', marginRight: '20px', background: '#FFFFFF', color: '#1480F3', border: '1px solid #1480F3', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontWeight: '500', fontFamily: 'Plus Jakarta Sans', }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Popup>
        <SnackBar
          open={open}
          severity={severity}
          message={message}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}
