import React, { useEffect, useState, } from "react";
import DataTable from "../../components/dataTable/DataTable";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
  IconButton,
  Grid,
  Button,
} from "@mui/material";
import { apiGET } from "../../utils/api";
import axios from "axios";
import { GET_EMPLOYEES_LIST, BASE_URL } from "../../utils/config";
import SnackBar from "../alert/SnackBar";
import Popup from "../popup/Popup";
import DeleteIcon from "../../assets/images/delete.svg";
import EditIcon from "../../assets/images/edit.svg";
import EmailIcon from "../../assets/images/emailIcon.png";
import image6 from "../../assets/images/image6.png";
import ReactWhatsapp from 'react-whatsapp';
import CommentIcon from "@mui/icons-material/Comment";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { BrowserRouter as Router, Switch, Route, Link, useHistory } from 'react-router-dom';


export default function ContactTable() {
  const [action, setAction] = useState("");
  const [filterContacts, setFilerContacts] = useState("");
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeObject, setEmployeeObject] = useState({});
  const [rowId, setRowId] = useState(null);
  const [openAssigneeModal, setOpenAssigneeModal] = useState(false);
  const [openProgressModal, setOpenProgressModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handleEmailClick = (email) => {
    // Redirect to the Gmail sending page
    window.location.href = `mailto:${email}`;
  };

  const contactColumns = [
    {
      accessorKey: "subject",
      header: "Subject",
      size: 230,
    },
    {
      accessorKey: "name",
      header: "Applicant Name",
      size: 160,
    },
    {
      accessorKey: "email",
      header: "Email Address",
      size: 230,
      Cell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: 'row' }}>
            <div style={{ cursor: 'pointer' }} >
              <img src={EmailIcon} alt='' onClick={() => handleEmailClick(params.row.original.email)} />
            </div>
            <div style={{ paddingLeft: '5px' }} >
              {params.row.original.email}
            </div>
          </div>
        );
      },
    },
    {
      accessorKey: "phone",
      header: "Phone",
      size: 230,
      Cell: (params) => {
        const renderedCellValue = params?.renderedCellValue;
        return (
          renderedCellValue && (
            <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'space-evenly' }}>
              <div >
                {renderedCellValue}
              </div>
              <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', paddingRight: '50px', flexGrow: 1, justifyContent: 'flex-end' }} >
                <ReactWhatsapp number={renderedCellValue?.toString()} style={{ borderStyle: 'none', background: 'none', cursor: 'pointer' }} >
                  <img src={image6} alt='' />
                </ReactWhatsapp>
              </div>
            </div>
          )

        );
      },
    },
    {
      accessorKey: "assignee",
      header: "Assignee",
      size: 230,
      Cell: (params) => {
        return (
          <>
            {
              params.row.original.employee ? (
                <div className="cell-with-assignee">
                  {params.row.original.employee
                    ? params.row.original.employee.first_name +
                    " " +
                    params.row.original.employee.first_name
                    : ""}
                </div>
              ) : (
                <div></div>
              )
            }
          </>
        );
      },
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 230,
      Cell: (params) => {
        return (
          <>
            {params.row.original.status === "To Do" ? (
              <div className={`cell-with-status todo`}>{"To Do"}</div>
            ) : params.row.original.status === "In Progress" ? (
              <div className={`cell-with-status in-progress`}>{"In Progress"}</div>
            ) : params.row.original.status === "Blocked" ? (
              <div className={`cell-with-status blocked`}>{"Blocked"}</div>
            ) : params.row.original.status === "Done" ? (
              <div className={`cell-with-status done`}>{"Done"}</div>
            ) : (
              <div></div>
            )}

          </>
        );
      }
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 250,
      Cell: (params) => {
        return (
          <div className="cell-action">
            <IconButton
              aria-label="progress"
              size="medium"
              onClick={
                () => {
                  setRowId(params.row.original._id);
                  setOpenProgressModal(true);
                }
              }
            >
              <SignalCellularAltIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              aria-label="assignee"
              size="medium"
              onClick={
                () => {
                  setRowId(params.row.original._id);
                  setOpenAssigneeModal(true);
                }
              }
            >
               <CommentIcon fontSize="inherit" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    getContactList();
    getEmployeesList();
  }, []);

  const getContactList = () => {
    apiGET("contact/forms")
      .then((res) => {
        console.log("contact List", res.data.contacts);
        setFilerContacts(res.data.contacts);
      })
      .catch((error) => {
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };
  const getEmployeesList = () => {
    axios
      .get(GET_EMPLOYEES_LIST)
      .then(function (response) {
        if (!response.data.error) {
          setEmployeeList(response.data.employees);
        }
      })
      .catch(function (error) {
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };

  const updateStatusSwitch = (expression, id) => {
    switch (expression) {
      case 0:
        setAction(expression);
        updateStatus("To Do");
        break;
      case 1:
        setAction(expression);
        updateStatus("In Progress");
        break;
      case 2:
        setAction(expression);
        updateStatus("Blocked");
        break;
      case 3:
        setAction(expression);
        updateStatus("Done");
        break;
      default:
        break;
    }
  };
  const updateStatus = (value) => {
    axios
      .patch(BASE_URL + "contactUs/update_status/" + rowId, {
        status: value,
      })
      .then(function (response) {
        setOpenProgressModal(false);
        if (!response.data.error) {
          setOpen(true);
          setMessage(response.data.success_msg);
          setSeverity("success");
        } else {
          setOpen(true);
          setMessage(response.data.error);
          setSeverity("error");
        }
      })
      .catch(function (error) {
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };
  const updateAssignee = () => {
    console.log(BASE_URL + "contactUs/update_assignee/" + rowId);
    axios
      .patch(BASE_URL + "contactUs/update_assignee/" + rowId, {
        employee_id: employeeObject._id,
      })
      .then(function (response) {
        setOpenAssigneeModal(false);
        if (!response.data.error) {
          setOpen(true);
          setMessage(response.data.success_msg);
          setSeverity("success");
        } else {
          setOpen(true);
          setMessage(response.data.error);
          setSeverity("error");
        }
      })
      .catch(function (error) {
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <DataTable
        data={filterContacts}
        columns={contactColumns}
        onCellEditCommit={(params) => setRowId(params.id)}
      />
      <Popup
        title="Set Progress"
        openPopup={openProgressModal}
        setOpenPopup={setOpenProgressModal}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl sx={{ minWidth: 400, marginY: 2 }} size="small">
              <InputLabel>Action</InputLabel>
              <Select
                value={action}
                label="Action"
                defaultValue={""}
                onChange={(e) => {
                  setAction(e.target.value);
                }}
              >
                <MenuItem value={0}>To Do</MenuItem>
                <MenuItem value={1}>In Progress</MenuItem>
                <MenuItem value={2}>Blocked</MenuItem>
                <MenuItem value={3}>Done</MenuItem>
              </Select>
            </FormControl>
            <Grid
              justifyContent={"flex-end"}
              alignItems={"end"}
              container
              spacing={1}
              direction={"row"}
              sx={{ minWidth: 410 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ textTransform: 'none', width: '79px', height: '45px', background: '#2D74FF', color: '#FFFFFF', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontFamily: 'Plus Jakarta Sans', }}
                  onClick={() => updateStatusSwitch(action)}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="medium"
                  sx={{ textTransform: 'none', width: '97px', height: '45px', marginRight: '20px', background: '#FFFFFF', color: '#1480F3', border: '1px solid #1480F3', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontWeight: '500', fontFamily: 'Plus Jakarta Sans', }}
                  onClick={() => setOpenProgressModal(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popup>
      <Popup
        title="Assign Task"
        openPopup={openAssigneeModal}
        setOpenPopup={setOpenAssigneeModal}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              options={employeeList}
              getOptionLabel={(employee, index) => employee.first_name}
              disablePortal
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(event, newInputValue) => {
                setEmployeeObject(newInputValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Employee" />
              )}
              renderOption={(props, employee) => (
                <Box component="li" {...props} key={employee._id}>
                  {employee.first_name + " " + employee.last_name}
                </Box>
              )}
              sx={{ minWidth: 400, marginY: 2 }}
            />
            <Grid
              justifyContent={"flex-end"}
              alignItems={"end"}
              container
              spacing={1}
              direction={"row"}
              sx={{ minWidth: 410 }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ textTransform: 'none', width: '79px', height: '45px', background: '#2D74FF', color: '#FFFFFF', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontFamily: 'Plus Jakarta Sans', }}
                  onClick={updateAssignee}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  size="medium"
                  sx={{ textTransform: 'none', width: '97px', height: '45px', marginRight: '20px', background: '#FFFFFF', color: '#1480F3', border: '1px solid #1480F3', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontWeight: '500', fontFamily: 'Plus Jakarta Sans', }}
                  onClick={() => setOpenAssigneeModal(false)}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Popup>
      <SnackBar
        open={open}
        severity={severity}
        message={message}
        handleClose={handleClose}
      />
    </>
  );
}
