import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Input
} from "@mui/material";
import { Paper } from "@material-ui/core";
import Navbar from "../../components/navbar/Navbar";
import SideBar from "../../components/sidebar/SideBar";
import { apiFileFormPost } from "../../utils/api";
import SunEditor from "suneditor-react";
import file from "../../assets/images/fileuploader.png";
import "./styles.scss";
import ListHeader from "../../components/listHeader/ListHeader";
import { apiGET } from "../../utils/api";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { ADD_BLOG } from "../../utils/config";

export default function AddBlog() {
  const [htmlData, setHtmlData] = useState("");
  const [title, setTitle] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  //new states
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategory, setSelectedcategory] = useState([]);
  const [fileInputKey, setFileInputKey] = useState(Date.now().toString());
  const [availableTags, setAvailableTags] = useState([]);
  const [availableCategorys, setAvailableCategorys] = useState([]);

  const onChange = (content) => {
    setHtmlData(content);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    console.log("file selected", event.target.files[0])
    setSelectedFiles(file);
  };


  const handleSubmit = async () => {
    try {
      if (!title) {
        prompt("Title is required");
        return;
      }

      if (!htmlData) {
        prompt("Description is required");
        return;
      }

      if (!selectedFiles) {
        prompt("Image is required");
        return;
      }

      if (!selectedTags.length) {
        prompt("At least one tag is required");
        return;
      }

      if (!selectedCategory.length) {
        prompt("At least one Category is required");
        return;
      }

      const blogFormData = new FormData();

      // Convert selectedTags array to an object
      const tagsObject = selectedTags
        .filter((tag) => typeof tag === "object" && tag.hasOwnProperty("name"))
        .map((tag) => ({ name: tag.name }));

      const CategoryObject = selectedCategory
        .filter((tag) => typeof tag === "object" && tag.hasOwnProperty("name"))
        .map((tag) => ({ name: tag.name, image: tag.image }));

      const data = {
        title: title,
        description: htmlData,
        tags: tagsObject,
        categories: CategoryObject,
      };

      blogFormData.append("image", selectedFiles);
      blogFormData.append("data", JSON.stringify(data));
      console.log("blos Post Data", blogFormData)

      const response = await axios.post(ADD_BLOG, blogFormData);
      const responseData = response.data;
      if (responseData.error === false) {
        setStatusMessage(responseData.success_msg);
        setHtmlData("");
        setTitle("");
        setSelectedFile(null);
        setSelectedTags([]); 
        setSelectedcategory([]);
        setFileInputKey(Date.now().toString());
        setTimeout(() => {
          setStatusMessage("");
        }, 3000);
      } else {
        setStatusMessage(responseData.error_msg);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchTagsFromDB();
    fetchCategoryFromDB();
  }, []);

  // Fetch available tags from the database or API
  const fetchTagsFromDB = async () => {
    try {
      // Your API call or database fetch to get the available tags
      const response = await apiGET("tags/get_tags");
      const tags = response.data.tags;
      setAvailableTags(tags);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const fetchCategoryFromDB = async () => {
    try {
      const response = await apiGET("category/get_categorys");
      console.log("categories", response)
      setAvailableCategorys(response.data.categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const handleTagChange = (event, values) => {
    console.log("values", values)
    setSelectedTags(values);
  };

  const handleCategoryChange = (event, values) => {
    console.log("values", values)
    setSelectedcategory(values);
  };

  return (
    <div className="box">
      <SideBar />
      <div className="box-container">
        <Navbar header={"Blogs"} />
        <ListHeader
          header={"Dashboard"}
          header1={"Blogs"}
          header2={"Add Blog"}
          firstLinks={"/"}
          secondLinks={"/blogs"}
        />
        <Paper className="form-container" style={{ height: '80vh', borderRadius: '10px', boxShadow: ' 0px 4px 12px rgba(0, 0, 0, 0.12)' }}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} >
              <Typography gutterBottom>
                Blogs Title *
              </Typography>
              <TextField
                required
                id="title"
                name="title"
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                fullWidth
                autoComplete="given-name"
                className="custom-textfield"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography gutterBottom>Social  Media</Typography>
              <Input
                required
                name="file"
                key={fileInputKey}
                variant="outlined"
                fullWidth
                onChange={handleFileInputChange}
                type="file"
                placeholder="Choose Blog Thumbnail"
                inputProps={{
                  accept: "image/*",
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                options={availableTags}
                getOptionLabel={(tag) => tag.name}
                value={selectedTags}
                onChange={handleTagChange}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" variant="outlined" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                options={availableCategorys}
                getOptionLabel={(tag) => tag.name}
                value={selectedCategory}
                onChange={handleCategoryChange}
                renderInput={(params) => (
                  <TextField {...params} label="Select Category" variant="outlined" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12} sx={{ paddingTop: "10px" }}>
              <Typography gutterBottom>
                Description
              </Typography>
              <div className="editor">
                <SunEditor
                  height={374}
                  setAllPlugins={true}
                  setOptions={{
                    font: [
                      "Poppins",
                      "Arial",
                      "tahoma",
                      "Courier New,Courier",
                      "verdana",
                    ],
                    buttonList: [
                      [
                        "font",
                        "fontSize",
                        "bold",
                        "underline",
                        "italic",
                        "strike",
                        "subscript",
                        "superscript",
                        "removeFormat",
                        "fontColor",
                        "hiliteColor",
                        "indent",
                        "outdent",
                        "align",
                        "list",
                        "horizontalRule",
                        "table",
                        "link",
                        "image",
                        "video",
                        "audio",
                        "fullScreen",
                        "showBlocks",
                        "codeView",
                        "undo",
                        "redo",
                        "preview",
                        "print",
                        "lineHeight",
                        "paragraphStyle",
                        "textStyle",
                      ],
                    ],
                  }}
                  name="description"
                  onChange={onChange}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                onClick={handleSubmit}
                variant="contained"
                size="medium"
                sx={{ textTransform: 'none', width: '79px', height: '45px', background: '#2D74FF', color: '#FFFFFF', borderRadius: '6px', padding: '11px 18px', fontSize: '18px', fontFamily: 'Plus Jakarta Sans', }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
