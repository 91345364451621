import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Dashboard from "./layout/dashboard/Dashboard";
import GetCustomersList from "./layout/customer/GetCustomersList";
import AddCustomer from "./layout/customer/AddCustomer";
import EditCustomer from "./layout/customer/EditCustomer";
import AddJob from "./pages/jobs/AddJob";
import GetJobList from "./pages/jobs/GetJobList";
import AddBlog from "./pages/blogs/AddBlog";
import GetBlogList from "./pages/blogs/GetBlogList";
import GetAppliedJobs from "./pages/appliedJobs/GetAppliedJobs";
import GetContactList from "./pages/contactUs/GetContactList";
import Setting from "./pages/settings/Setting";
import UpdateJob from "./pages/jobs/UpdateJob";
import AddEmployee from "./pages/employee/AddEmployee";
import GetEmployeesList from "./pages/employee/GetEmployeeList";
import UpdateBlog from"./pages/blogs/UpdateBlog";
import Attendance from"./pages/employee/Attendance";
import Leave from"./pages/employee/Leave";
import Leaves from"./pages/employee/LeaveList";
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // Custom breakpoint for small screens
      md: 1024, // Custom breakpoint for medium screens
      lg: 1280, // Custom breakpoint for large screens
      xl: 1920, // Custom breakpoint for extra-large screens
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/">
          <Route index element={<Dashboard />} />
          <Route path="jobs">
            <Route index element={<GetJobList />} />
            <Route path="add" element={<AddJob />} />
            <Route path="updatejob" element={<UpdateJob />} />
          </Route>
          <Route path="employee">
            <Route index element={<GetEmployeesList />} />
            <Route path="add" element={<AddEmployee />} />
          </Route>
          <Route path="blogs">
            <Route index element={<GetBlogList />} />
            <Route path="add" element={<AddBlog />} />
            <Route path="updateblog" element={<UpdateBlog/>} />
          </Route>
          <Route path="jobs/applied">
            <Route index element={<GetAppliedJobs />} />
          </Route>
          <Route path="contacts">
            <Route index element={<GetContactList />} />
          </Route>
          <Route path="settings">
            <Route index element={<Setting />} />
          </Route>
          <Route path="attendance">
            <Route index element={<Attendance />} />
          </Route>
          <Route path="leave">
            <Route index element={<Leaves/>} />
            <Route path="add" element={<Leave />} />
          
          </Route>
         
        </Route>
      </Routes>
    </Router>
    </ThemeProvider>
  );
}

export default App;
