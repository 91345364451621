import React, {useEffect, useState} from "react";
import { Box, Button, Typography,Divider } from "@mui/material";
import { Link } from "react-router-dom";
import Links from '@mui/material/Link';
import plus from "../../assets/images/plus.png";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ficon from "../../assets/images/ficon.png"
import group from "../../assets/images/Group.png"

export default function ListHeader({
  firstButton,
  secondButton,
  thirdButton,
  firstButtonText,
  secondButtonText,
  thirdButtonText,
  firstLink,
  secondLink,
  thirdLink,
  firstLinks,
  secondLinks,
  header,
  header1,
  header2,
  forthButton,
  fifthButton,
  sixButton,
  showDateButton,
  // currentDate,
  handlePreviousDate,
  handleNextDate,
  showDateInput, // boolean to determine whether to show the date input
  // currentDate,
  // setCurrentDate,
  fetchSingleDateData,
})

{

  const breadcrumbs = [
    <Links
      underline="hover"
      key="2"
      color="inherit"
      href={firstLinks}
      sx={{fontFamily:'Plus Jakarta Sans',fontStyle:'normal',fontSize:'18px',color:'#171832',opacity:'0.6'}}
    >
      {header}
    </Links>,
     <Links underline="none" key="1" href={secondLinks} sx={{ fontFamily:'Plus Jakarta Sans',fontStyle:'normal',fontSize:'18px',color:'#171832',fontWeight: header && header1 && header2 ? " " : "600",opacity: header && header1 && header2 ? '0.6' : 1,}} >
     {header1}
    </Links>,
     header2 && (
      <Box key="3">
        <Typography sx={{fontFamily:'Plus Jakarta Sans',fontStyle:'normal',fontSize:'18px',color:'#171832',fontWeight:'600'}} >{header2}</Typography>
      </Box>
    ),
  ];
  const [currentDate, setCurrentDate] = useState(getFormattedDate());
 

  const handleDateChange = async (e) => {
    const selectedDate = e.target.value;
    const today = getFormattedDate(); // Get the current date
  
    // Check if the selected date is not in the future
    if (selectedDate <= today) {
      setCurrentDate(selectedDate);
  
      // Call fetchSingleDateData with the selected date as a parameter
      await fetchSingleDateData(selectedDate);
    } else {
      // Display a message to the user or handle it as needed
      alert("Please select a date not in the future.");
      // You can choose to reset the date to the current date or take other actions here.
    }
  };
  
  
  

  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  return (
    <>
    <Divider sx={{margin:"0px 17px",border:'1px solid #F4F4F4'}} />
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      alignItems={"center"}
      px={4}
      py={2}
      sx={{background:'#FFFFFF'}}
    >
       <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        {firstButton ? (
          <Button
            variant="contained"
            size="small"
            component={Link}
            to={firstLink}
            sx={{padding:'11px 18px !important',borderRadius:"6px !important",background:"#2D74FF !important",color:'#FFFFFF !important',textTransform: 'none', }}
            startIcon={<img src={plus} alt='' width="14px" height="14px" />}
          >
            {firstButtonText}
          </Button>
        ) : null}
        {secondButton ? (
          <Button
            variant="contained"
            size="small"
            color="success"
            component={Link}
            to={secondLink}
            sx={{ marginX: 1 }}
          >
            {secondButtonText}
          </Button>
        ) : null}
        {thirdButton ? (
          <Button
            variant="contained"
            size="small"
            color="success"
            component={Link}
            to={thirdLink}
            sx={{ marginX: 1  }}
          >
            {thirdButtonText}
          </Button>
        ) : null}
           {showDateInput && (
          <div className='date' >
            <input  style={{ width: '140px', height: '35px', borderRadius: '5px', alignItems: 'center', marginRight:'10px', border:'0.1px solid rgba(45, 116, 255, 1)', color:'rgba(45, 116, 255, 1)',paddingLeft:'10px' , cursor: 'pointer'}}
        id="date-input"
        type="date"
        value={currentDate}
        onChange={handleDateChange}
      />
          </div>
        )}
        {forthButton ? (
          <button
          className="Filterscreen"
          onClick={forthButton}
        >
          <div style={{display:'flex',justifyContent:'center'}}>
          <img className="filter-icon" src={ficon} alt=""/>
          Filter
          </div>
        </button>
        ) : null}
        {fifthButton ? (
          <button
          className="export"
          onClick={fifthButton}
        >
          <div style={{display:'flex',justifyContent:'center'}}>
          <img className="group-icon" src={group} alt=""/>
          Export
          </div>
        </button>
        ) : null}
       
      </Box>
    </Box>
    </>
  );
}
