import { React, useState } from "react";

import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/Navbar";
import { apiDELETE } from "../../utils/api";
import AppliedJobsTable from "../../components/tables/AppliedJobsTable";
import ListHeader from "../../components/listHeader/ListHeader";
import Widgets from "../../components/widgets/Widgets";
import {
  DELETE_AL_DATA,
} from "../../utils/config";

export default function GetAppliedJobs() {
  const [statusMessage, setStatusMessage] = useState("");
  const [filterJobs, setFilterJobs] = useState("");
  const handleDelete = () => {
    const confirmed = window.confirm("Are you sure you want to delete all data?");
    if (confirmed) {
      apiDELETE( DELETE_AL_DATA)
        .then((res) => {
          if (res.data.status === true) {
            setStatusMessage(res.data.message);
            setFilterJobs([]);
            alert("Deleted all data successfully");
            setTimeout(() => {
              setStatusMessage("");
             
            }, 3000);
          }
          
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <div className="list">
      <Sidebar />
      <div className="list-container">
        <Navbar header={"Applications"} />
        <ListHeader
         header={"Dashboard"}
         header1={"Applicant"}
         firstLinks={"/"}
         secondLinks={"/jobs/applied"}
        />
        <AppliedJobsTable />
      </div>
    </div>
  );
}
