import React from "react";
import "./sidebar.scss";

import { Stack, Box } from '@mui/material';
import { Link } from "react-router-dom";
import logo from "../../assets/images/silicon.svg";
import minimize from "../../assets/images/minimize.svg";
import dashboard from "../../assets/images/dashboard.svg";
import jobs from "../../assets/images/briefcase.svg";
import applicants from "../../assets/images/Applicants.svg";
import attendance from "../../assets/images/Attendacne.png";
import leave from "../../assets/images/Leaves.png";
import blogs from "../../assets/images/Blogs.svg";
import contact from "../../assets/images/Contacts.svg"
import setting from "../../assets/images/Setting.svg"
import logout from "../../assets/images/logout.png"


export default function SideBar() {
  return (
    <Stack className="sidebar">
      <Stack className="top">
        <img src={logo} alt="Silicon Nexus" />
        <Stack direction="row" className="divider">
          <hr className="horizental-line" />
          <img src={minimize} alt="" className="minimize" />
        </Stack>
      </Stack>
      <Stack className="center" sx={{ paddingTop: "0px" }}>
        <Box sx={{ paddingBottom: "10px" }} >
          <p className="title1">MAIN MENU</p>
          <Link to="/" className="sidbar-box">
            <img src={dashboard} alt="" className="icon" />
            <p className="icon-text" style={{cursor:'pointer'}}>Dashboard</p>
          </Link>
        </Box>

        <Box sx={{ paddingBottom: "10px" }}>
          <p className="title1">CARRERS</p>
          <Stack>
            <Link to="/jobs" className="sidbar-box">
              <img src={jobs} alt="" className="icon" />
              <p className="icon-text" style={{cursor:'pointer'}}>Jobs</p>
            </Link>
            <Link to='/employee' className="sidbar-box">
              <img src={applicants} alt="" className="icon" />
              <p className="icon-text" style={{cursor:'pointer'}}>Employee</p>
            </Link>
            <Link to='/jobs/applied' className="sidbar-box">
              <img src={applicants} alt="" className="icon" />
              <p className="icon-text" style={{cursor:'pointer'}}>Applicant's </p>
            </Link>
          </Stack>
        </Box>

        <Box sx={{ paddingBottom: "10px" }}>
          <p className="title1">BLOGS</p>
          <Stack>
            <Link to='/blogs' className="sidbar-box">
              <img src={blogs} alt="" className="icon" />
              <p className="icon-text" style={{cursor:'pointer'}}>Blogs</p>
            </Link>

          </Stack>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <p className="title1">ATTENDANCE</p>
          <Stack>
            <Link to='/attendance' className="sidbar-box">
              <img src={attendance} alt="" className="icon" />
              <p className="icon-text" style={{cursor:'pointer'}}>Attendance</p>
            </Link>

          </Stack>
        </Box>
        <Box sx={{ paddingBottom: "10px" }}>
          <p className="title1">LEAVE</p>
          <Stack>
            <Link to='/leave' className="sidbar-box">
              <img src={leave} alt="" className="icon" />
              <p className="icon-text" style={{cursor:'pointer'}}>Leave</p>
            </Link>

          </Stack>
        </Box>

        <Box sx={{ paddingBottom: "10px" }}>
          <p className="title1">LEADS</p>
          <Stack>
            <Link to="/contacts" className="sidbar-box">
              <img src={contact} alt="" className="icon" />
              <p className="icon-text" style={{cursor:'pointer'}}>Clients Contacts</p>
            </Link>
          </Stack>
        </Box>

        <Box>
          <p className="title1">HELP & SUPPORT</p>
          <Stack>
            <Link to="/settings" className="sidbar-box">
              <img src={setting} alt="" className="icon" />
              <p className="icon-text" style={{cursor:'pointer'}}>Settings</p>
            </Link>
          </Stack>
        </Box>

        <Box sx={{ position: "absolute", bottom: "0px" }} style={{ width: "100%" }}>
          <Stack>
            <Stack direction="row" style={{ padding: "15px 25px", opacity: 1 }}
              className="sidbar-box"
            >
              <img src={logout} alt="" />
              <p className="logout-text" style={{ color: "white" }}>Log out</p>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
