import React from "react";
import { Stack, Switch, Typography, Divider } from "@mui/material";
import { styled } from '@mui/material/styles';
import "./styles.scss";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

export default function Password() {

  return (
    <Stack className="email-info">
      <Stack sx={{ padding: "30px 0px" }}>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingBottom: '10px', paddingLeft: '20px' }}>
          <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          <Typography className="email-heading">Opinion Emails</Typography>
        </Stack>
        <Stack sx={{ paddingBottom: '20px', paddingLeft: '20px' }}>
          <Typography className="email-text" >Give Feedback on Mangrove.</Typography>
        </Stack>
        <Divider />
      </Stack>

      <Stack >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingBottom: '10px', paddingLeft: '20px' }}>
          <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          <Typography className="email-heading">Reminder Emails</Typography>
        </Stack>
        <Stack sx={{ paddingBottom: '20px', paddingLeft: '20px' }}>
          <Typography className="email-text">Get notifications you may have missed.</Typography>
        </Stack>
        <Divider />
      </Stack>

      <Stack sx={{ padding: "30px 0px" }}>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingBottom: '10px', paddingLeft: '20px' }}>
          <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          <Typography className="email-heading">Product Emails</Typography>
        </Stack>
        <Stack sx={{ paddingBottom: '20px', paddingLeft: '20px' }}>
          <Typography className="email-text">Get tips on mangrove tools.</Typography>
        </Stack>
        <Divider />
      </Stack>

      <Stack >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingBottom: '10px', paddingLeft: '20px' }}>
          <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
          <Typography className="email-heading">News Emails</Typography>
        </Stack>
        <Stack sx={{ paddingBottom: '20px', paddingLeft: '20px' }}>
          <Typography className="email-text">Learn about the new mangrove fearture.</Typography>
        </Stack>
        <Divider />
      </Stack>

    </Stack>
  )
}


