import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import "./widget.scss";
import { makeStyles, useTheme } from '@material-ui/core';
import clsx from "classnames";
const useStyles = makeStyles(theme => ({
  textExample: {
   
    [theme.breakpoints.down('md')]: {
      height: '150px',
      padding:'10px',
      gap:'2 !important'
    }
  },
  textExample1:{
    [theme.breakpoints.down('md')]: {
      fontSize:'14px !important'
    }
  },
  textExample2:{
    [theme.breakpoints.down('md')]: {
      height: '38px !important'
    }
  },
  textExample3:{
    [theme.breakpoints.down('md')]: {
      width:'40px !important',
      height:'40px !important'
    }
  },
  textExample4:{
    fontSize:'16px',
    [theme.breakpoints.down('md')]: {
      fontSize:'12px !important'
    }
  },
  // textExample5:{
  //   [theme.breakpoints.down('md')]: {
  //     width:'40px !important',
  //     height:'40px !important'
  //   }
  // }

}));


export default function CareerStats({ image, title, tags }) {

  const classes = useStyles();
  return (
    <Box  gap={2}  className={clsx(classes.textExample, "dashboard-cards")} >
      <Stack gap={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Stack className={clsx(classes.textExample2, "vertical-line")} ></Stack>
        <Stack><img className={clsx(classes.textExample3, "card-img")}  src={image} alt='careers' /></Stack>
        <Typography className="dashboard-cards-title">{title}</Typography>
      </Stack>
      <Stack gap={1}>
        {tags?.map((element, index) => (
          <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography  className={clsx(classes.textExample4, "dashboard-cards-tags")} key={index}>{element}</Typography>
            <Typography className={clsx(classes.textExample1, "dashboard-cards-result")}>685</Typography>
          </Stack>
        ))}
      </Stack>
      <Stack></Stack>
      <Stack></Stack>

    </Box>
  );
}
