import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/Navbar";
import ListHeader from "../../components/listHeader/ListHeader";
import "./employee.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import axios from "axios";
import Close from "../../assets/images/close.png";
export default function LeaveList() {
  const [leaveData, setLeaveData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [filteredLeaveData, setFilteredLeaveData] = useState([]);
  const [showWinModal, setShowWinModal] = useState(false);
  const [editData, setEditData] = useState([]);
  useEffect(() => {
    fetch("https://si-nexus-back-end.vercel.app/api/leave/get_leaves")
      .then((response) => response.json())
      .then((data) => {
        setLeaveData(data.response);
        setFilteredLeaveData(data.response);
      });
  }, []);

  const filterLeaveData = (status) => {
    if (status === "All") {
      setFilteredLeaveData(leaveData);
    } else {
      const filteredData = leaveData.filter((leave) => leave.status === status);
      setFilteredLeaveData(filteredData);
    }
    setSelectedStatus(status);
  };

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }
  function calculateRemainingDays(fromDate, toDate) {
    const now = new Date();
    const from = new Date(fromDate);
    const to = new Date(toDate);
    const difference = to - now;
    const daysRemaining = Math.ceil(difference / (1000 * 3600 * 24));
    return daysRemaining >= 0 ? daysRemaining : 0;
  }
  
  const WinModal = () => {
    const handleUpdateLeave = () => {
      axios
        .put(
          `https://si-nexus-back-end.vercel.app/api/leave/${editData._id}`,
          editData
        )
        .then((response) => {
          console.log("Leave updated successfully.");
          setShowWinModal(false); 
        })
        .catch((error) => {
          console.error("An error occurred while updating the leave:", error);
        });
    };

    const [employeeList, setEmployeeList] = useState([]);
    useEffect(() => {
      fetch("https://si-nexus-back-end.vercel.app/api/employee/get_employees")
        .then((response) => response.json())
        .then((employeedata) => setEmployeeList(employeedata.employees))
        .catch((error) => console.error("Error fetching data:", error));
    }, []);
    function formatDate(dateStr) {
      const date = new Date(dateStr);
      const year = date.getFullYear();
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const day = ("0" + date.getDate()).slice(-2);
  
      return `${year}-${month}-${day}`;
    }
    return (
      <div className="popup-overlay">
        <div className="popup-1">
          <div className="header">
            <h2 style={{ paddingLeft: "30px" }}>Edit Leave</h2>
            <button className="close-button" onClick={() => setShowWinModal(false)}>
              <img src={Close} alt="Close" />
            </button>
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <InputLabel
                style={{ marginLeft: "2vw" }}
                htmlFor="employee-select"
              >
                Employee Name
              </InputLabel>
              <Select
                id="employee-select"
                style={{
                  width: "15vw",
                  height: "6vh",
                  border: "none",
                  borderRadius: "10px",
                  marginTop: "10px",
                  paddingLeft: "10px",
                  color: "black",
                  marginLeft: "2vw",
                }}
                value={editData.employee_name || ""}
                onChange={(e) => {
             
                  setEditData({
                    ...editData,
                    employee_name: e.target.value,
                  });
                }}
              >
                <MenuItem value="">
                  <em>Select an employee</em>
                </MenuItem>
                {Array.isArray(employeeList) &&
                  employeeList.map((employee) => (
                    <MenuItem
                      key={employee._id}
                      value={employee.first_name + " " + employee.last_name}
                    >
                      {employee.first_name + " " + employee.last_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="approver-select">Approved by</InputLabel>
              <Select
                id="approver-select"
                style={{
                  width: "15vw",
                  height: "6vh",
                  border: "none",
                  borderRadius: "10px",
                  marginTop: "10px",
                  paddingLeft: "10px",
                  color: "black",
                }}
                value={editData.approved_by || ""}
                onChange={(e) => {
                 
                  setEditData({
                    ...editData,
                    approved_by: e.target.value,
                  });
                }}
              >
                <MenuItem value="">
                  <em>Select an approver</em>
                </MenuItem>
                <MenuItem value="Brirra Fayyaz">Brirra Fayyaz</MenuItem>
                <MenuItem value="Haris Mehar">Haris Mehar</MenuItem>
                <MenuItem value="Kalbe Abbas">Kalbe Abbas</MenuItem>
                <MenuItem value="Furqan Bhatti">Furqan Bhatti</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel
                style={{ marginLeft: "2vw" }}
                htmlFor="approver-select"
              >
                Leave Type
              </InputLabel>
              <Select
                id="leave_type"
                style={{
                  width: "15vw",
                  height: "6vh",
                  border: "none",
                  borderRadius: "10px",
                  marginTop: "10px",
                  paddingLeft: "10px",
                  color: "black",
                  marginLeft: "2vw",
                }}
                value={editData.leave_type || ""}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    leave_type: e.target.value,
                  });
                }}
              >
                <MenuItem value="">
                  <em>Select leave Type</em>
                </MenuItem>
                <MenuItem value="Sick Leave">Sick Leave</MenuItem>
                <MenuItem value="Personal Work">Personal Work</MenuItem>
                <MenuItem value="Marriage leave">Marriage leave</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="approver-select">Number of leave</InputLabel>
              <TextField
                required
                type="number"
                value={editData.
                  number_of_days || ""}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    
number_of_days: e.target.value,
                  });
                }}
                sx={{
                  width: "15vw",
                  height: "6vh",
                  border: "none",
                  borderRadius: "20px",
                  marginTop: "10px",
                  paddingLeft: "10px",
                  color: "black",
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel
                style={{ marginLeft: "2vw" }}
                htmlFor="approver-select"
              >
                From Date
              </InputLabel>
              <input
                type="date"
                style={{
                  width: "15vw",
                  height: "6vh",
                  border: "0.1px solid grey",
                  borderRadius: "10px",
                  marginTop: "10px",
                  paddingLeft: "10px",
                  color: "black",
                  marginLeft: "2vw",
                }}
                value={formatDate(editData.from_date) || ""} 
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    from_date: e.target.value,
                  });
                }}
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="approver-select">To Date</InputLabel>
              <input
                type="date"
                style={{
                  width: "15vw",
                  height: "6vh",
                  border: "0.1px solid grey",
                  borderRadius: "10px",
                  marginTop: "10px",
                  paddingLeft: "10px",
                  color: "black",

                }}
                value={formatDate(editData.to_date) || ""} 
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    to_date: e.target.value,
                  });
                }}
              />
            
            </Grid>
            <Grid item xs={12} sm={6}>
        
            <InputLabel  style={{ marginLeft:'2vw'}}   htmlFor="status-select">Status</InputLabel>
            <Select
              id="status"
              style={{
                width: "31vw",
                height: "6vh",
                border: "none",
                borderRadius: "10px",
                marginTop: "10px",
                paddingLeft: "10px",
                color: "black",
                marginLeft:'2vw'
              }}
              value={editData.status || ""}
                onChange={(e) => {
                  setEditData({
                    ...editData,
                    status: e.target.value,
                  });
                }}
            >
              <MenuItem value="">
                <em>Select a status</em>
              </MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputLabel
                style={{ marginLeft: "2vw" }}
                htmlFor="approver-select"
              >
                Reason/Subject
              </InputLabel>
              <input
  required
  multiline
  rows={3}
  value={editData.reason || ""}
  onChange={(e) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      reason: e.target.value,
    }));
  }}
 autoFocus
  style={{
    width: "31vw",
    height: "6vh",
    border: "1px solid grey",
    borderRadius: "10px",
    marginTop: "10px",
    paddingLeft: "10px",
    color: "black",
    marginLeft: "2vw",
  }}
/>

            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <button
                onClick={handleUpdateLeave}
                style={{ marginLeft: "6vw", marginTop: "5vh" ,cursor: 'pointer'}}
                className="modal-btn-2"
              >
                Edit Leave
              </button>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  };

  //delete leave

  const handleDeleteClick = (leaveId) => {
    console.log("Deleting leave with ID:", leaveId);

    axios
      .delete(`https://si-nexus-back-end.vercel.app/api/leave/${leaveId}`)
      .then((response) => {
        const updatedLeaveData = leaveData.filter(
          (leave) => leave._id !== leaveId
        );
        setLeaveData(updatedLeaveData);
        if (updatedLeaveData) {
          setTimeout(() => {
            alert("leave Delete successfully");
          }, 1000);
        } else {
          console.error(
            "Delete operation failed. Status code:",
            response.status
          );
        }
      })
      .catch((error) => {
        console.error("An error occurred while deleting the leave:", error);
      });
  };

  return (
    <div className="list">
      <Sidebar />
      <div className="list-container">
        <Navbar header={"Employee Leaves"} />
        <ListHeader
          header={"Dashboard"}
          header1={"Leaves"}
          firstLinks={"/"}
          secondLinks={"/leaves"}
          firstButton={true}
          firstButtonText={"Add New Leave"}
          firstLink={"/leave/add"}
        />
        <div className="filterss">
          <div>
            <p
              style={{
                color: "black",
                fontSize: "18px",
                paddingLeft: "15px",
                fontWeight: "600",
                paddingTop: "15px",
              }}
            >
              Leaves
            </p>
          </div>
          <div
            style={{
              color: "black",
              fontSize: "18px",
              fontWeight: "600",
              paddingTop: "15px",
              marginRight: "20px",
              borderRadius: "10px",
            }}
          >
            <div className="btn-group">
              <button
                style={{
                  color: "grey",
                  fontSize: "14px",
                  fontWeight: "400",
                  width: "80px",
                  height: "30px",
                  background: "white",
                  border: "0.5px solid grey",
                  borderTopLeftRadius: "10px",
                  borderBottomLeftRadius: "10px",
                  color: selectedStatus === "All" ? "black" : "initial",
                  backgroundColor:
                    selectedStatus === "All" ? "white" : "initial",
                  fontWeight: selectedStatus === "All" ? "600" : "initial",
                }}
                onClick={() => filterLeaveData("All")}
                className={`btn ${
                  selectedStatus === "All" ? "selected-button" : ""
                }`}
              >
                All
              </button>
              <button
                style={{
                  color: "grey",
                  fontSize: "14px",
                  fontWeight: "400",
                  width: "80px",
                  height: "30px",
                  background: "white",
                  border: "0.5px solid grey",
                  color: selectedStatus === "Approved" ? "black" : "initial",
                  backgroundColor:
                    selectedStatus === "Approved" ? "white" : "initial",
                  fontWeight: selectedStatus === "Approved" ? "600" : "initial",
                }}
                onClick={() => filterLeaveData("Approved")}
                className={`btn ${
                  selectedStatus === "Approved" ? "selected-button" : ""
                }`}
              >
                Approved
              </button>
              <button
                style={{
                  color: "grey",
                  fontSize: "14px",
                  fontWeight: "400",
                  width: "80px",
                  height: "30px",
                  background: "white",
                  border: "0.5px solid grey",
                  color: selectedStatus === "Pending" ? "black" : "initial",
                  backgroundColor:
                    selectedStatus === "Pending" ? "white" : "initial",
                  fontWeight: selectedStatus === "Pending" ? "600" : "initial",
                }}
                onClick={() => filterLeaveData("Pending")}
                className={`btn ${
                  selectedStatus === "Pending" ? "selected-button" : ""
                }`}
              >
                Pending
              </button>
              <button
                style={{
                  color: "grey",
                  fontSize: "14px",
                  fontWeight: "400",
                  width: "80px",
                  height: "30px",
                  background: "white",
                  border: "0.5px solid grey",
                  borderTopRightRadius: "10px",
                  borderBottomRightRadius: "10px",
                  color: selectedStatus === "Rejected" ? "black" : "initial",
                  backgroundColor:
                    selectedStatus === "Rejected" ? "white" : "initial",
                  fontWeight: selectedStatus === "Rejected" ? "600" : "initial",
                }}
                onClick={() => filterLeaveData("Rejected")}
                className={`btn ${
                  selectedStatus === "Rejected" ? "selected-button" : ""
                }`}
              >
                Rejected
              </button>
            </div>
          </div>
        </div>
        <div className="card-area">
          {filteredLeaveData.length === 0 ? (
            <p style={{ paddingLeft: "30vw", paddingTop: "20vh" }}>
              No data found
            </p>
          ) : (
            filteredLeaveData.map((leave) => (
              <div className="leave-card" key={leave.id}>
                <div className="emp-name">
                  <h5>{leave.employee_name}</h5>
                  <div>
                    <button
                      onClick={() => {
                        setShowWinModal(true);
                        setEditData(leave);
                      }}
                      style={{
                        width: "45px",
                        height: "35px",
                        border: "1.5px solid rgba(45, 116, 255, 1)",
                        borderRadius: "10px",
                        color: "rgba(45, 116, 255, 1)",
                        background: "white",
                        marginRight: "10px"
                        ,cursor: 'pointer'
                      }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteClick(leave._id)}
                      style={{
                        width: "50px",
                        height: "35px",
                        border: "1.5px solid red",
                        borderRadius: "10px",
                        color: "red",
                        background: "white",
                        marginRight: "10px"
                        ,cursor: 'pointer'
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div className="emp-data">
                  <div>
                    <p
                      style={{
                        color: "#064782",
                        fontSize: "16px",
                        paddingLeft: "5px",
                        fontWeight: "600",
                      }}
                    >
                      Leave Type{" "}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        paddingTop: "5px",
                        paddingLeft: "5px",
                        fontWeight: "500",
                      }}
                    >
                      {leave.leave_type}
                    </p>
                    <p
                      style={{
                        color: "rgba(231, 44, 121, 1)",
                        fontSize: "12px",
                        paddingTop: "15px",
                        paddingLeft: "5px",
                      }}
                    >
                      <span>Status:</span>&nbsp;{leave.status}{" "}
                    </p>
                  </div>
                  <div>
                    <p
                      style={{
                        fontSize: "12px",
                        paddingTop: "20px",
                        paddingLeft: "40px",
                      }}
                    >
                      ({formatDate(leave.from_date)} to{" "}
                      {formatDate(leave.to_date)})
                    </p>
                    <p
                      style={{
                        fontSize: "12px",
                        paddingTop: "10px",
                        paddingLeft: "80px",
                      }}
                    >
                      Remaining Days:{" "}
                      {calculateRemainingDays(leave.from_date, leave.to_date)}
                    </p>
                  </div>
                </div>
                <div className="reason">
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "600",
                      paddingLeft: "10px",
                      paddingTop: "10px",
                    }}
                  >
                    Reason/Subject
                  </p>
                  <p
                    style={{
                      fontSize: "14px",
                      paddingLeft: "10px",
                      color: "rgba(153, 153, 153, 1)",
                    }}
                  >
                    {leave.reason.length > 120
                      ? leave.reason.slice(0, 120) + "..."
                      : leave.reason}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
        {showWinModal && <WinModal setShowWinModal={setShowWinModal} />}
      </div>
    </div>
  );
}
