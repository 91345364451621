
import axios from "axios";



//const BASE_URL = "http://localhost:4000/api"
const BASE_URL = "https://si-nexus-back-end.vercel.app/api"

const apiPOST = async (api, body) => {
    const res = await axios({
        method: "POST",
        url: `${BASE_URL}/${api}`,
        data: body,
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
        },
    });

    return await res;
}
const apiFileFormPost = async (api, body) => {
    const res = await axios({
        method: "post",
        url: `${BASE_URL}/${api}`,
        data: body,
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
    return await res
}

const apiGET = async (api) => {
    const res = await axios({
        method: 'GET',
        url: `${BASE_URL}/${api}`,
        headers: {
            "Content-Type": "application/json"
        }
    })
    return await res;
}

const apiPUT = async (api, body) => {
    const res = await axios({
        method: "PUT",
        url: `${BASE_URL}/${api}`,
        data: body,
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
        },
    });

    return await res.json()
}

const apiPATCH = async (api, body) => {
    const res = await axios({
        method: "PATCH",
        url: `${BASE_URL}/${api}`,
        data: body,
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
        },
    });

    return await res.json()
}

const jobsApiPATCH = async (api, body, id) => {
    const res = await axios({
        method: "PATCH",
        url: `${BASE_URL}/${api}/${id}`,
        data: body,
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
        },
    });
    return await res

}

const apiDELETE = async (api, id) => {
    try {
        const res = await axios({
            method: "DELETE",
            url: `${BASE_URL}/${api}/${id}`,
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
            },
        });

        // Handle success response
        if (res.status === 200) {
            // Display success message
            alert('Data deleted successfully');
        }
        return res;

    } catch (error) {
        // Handle error response
        console.error('Error deleting data:', error);
        throw error;
    }
}


export { apiPOST, apiGET, apiPATCH,jobsApiPATCH , apiDELETE, apiPUT, apiFileFormPost }