import React from 'react'
import './dashboard.scss'
import SideBar from '../../components/sidebar/SideBar'
import Navbar from '../../components/navbar/Navbar'
import CareerStats from '../../components/widgets/CareerStats'
import { Stack, Grid } from '@mui/material'
import careers from "../../assets/images/careers.png"
import blogs from "../../assets/images/blogs.png"
import clients from "../../assets/images/clients.png"
import contacts from "../../assets/images/contacts.png"
import RevenueChart from "../../components/charts/RevenueChart"


export default function Dashboard() {

  const card1_tags = ['Job Posted', 'Applicants', 'Hired']
  const card2_tags = ['Blogs Posted', 'Interaction Ratio', 'Viewers / Readers']
  const card3_tags = ['Services Requests', 'Requests Served', 'Hired']
  const card4_tags = ['Visitors ', 'Respond ', 'Feedback']

  return (
    <div className='dashboard'>
      <SideBar />
      <div className='dashboard-container'>
        <Navbar header={"Welcome Back, Aqib Malik👏🏻"} />
        <div style={{ marginTop: '40px', marginLeft: '20px', marginRight: '20px' }}>
          <Grid container spacing={2} >
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <CareerStats image={careers} title='Careers' tags={card1_tags} />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <CareerStats image={blogs} title='Blogs' tags={card2_tags} />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <CareerStats image={clients} title='Clients' tags={card3_tags} />
            </Grid>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <CareerStats image={contacts} title='Client Contacts' tags={card4_tags} />
            </Grid>
          </Grid>
        </div>
        <RevenueChart />
      </div>
    </div>
  )
}
