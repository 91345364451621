import React from 'react'
import { Stack, Box, Grid, Typography } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import more from "../../assets/images/more.svg"
import "./Revenue.scss"

export default function RevenueChart() {
    const data_tags = [
        {
            tag: 'Target',
            value: '$23.5k',
            background: '#191F2F'
        },
        {
            tag: 'Last Week',
            background: '#1480F3',
            value: '$23.5k'
        },
        {
            tag: 'Last Month',
            value: '$23.5k',
            background: '#E4E9F1'
        },

    ]
    const data = [
        {
            "id": "javascript",
            "label": "javascript",
            "value": 439,
            "color": "hsl(177, 70%, 50%)"
        },
        {
            "id": "ruby",
            "label": "ruby",
            "value": 552,
            "color": "hsl(126, 70%, 50%)"
        },
        {
            "id": "hack",
            "label": "hack",
            "value": 77,
            "color": "hsl(27, 70%, 50%)"
        },
    ]
    return (
        <div className='revenue-container'>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} lg={4} >
                    <Box className='revenue-card'>
                        <Box display='flex' flexDirection='row' justifyContent='space-between'>
                            <Typography>Total Revenue </Typography>
                            <Box><img src={more} alt='' /></Box>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={3} md={3} lg={9} >
                                <div style={{ height: '80%', marginTop: "30px" }}>
                                    <ResponsivePie
                                        data={data}
                                        margin={{ top: 10, right: 80, bottom: 30, left: 10}}
                                        innerRadius={0.5}
                                        padAngle={0.7}
                                        cornerRadius={3}
                                        activeOuterRadiusOffset={8}
                                        borderWidth={1}
                                        borderColor={{
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    0.2
                                                ]
                                            ]
                                        }}
                                        arcLinkLabelsSkipAngle={10}
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={2}
                                        arcLinkLabelsColor={{ from: 'color' }}
                                        arcLabelsSkipAngle={10}
                                        arcLabelsTextColor={{
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    2
                                                ]
                                            ]
                                        }}
                                        // defs={[
                                        //     {
                                        //         id: 'dots',
                                        //         type: 'patternDots',
                                        //         background: 'inherit',
                                        //         color: 'rgba(255, 255, 255, 0.3)',
                                        //         size: 4,
                                        //         padding: 1,
                                        //         stagger: true
                                        //     },
                                        //     {
                                        //         id: 'lines',
                                        //         type: 'patternLines',
                                        //         background: 'inherit',
                                        //         color: 'rgba(255, 255, 255, 0.3)',
                                        //         rotation: -45,
                                        //         lineWidth: 6,
                                        //         spacing: 10
                                        //     }
                                        // ]}
                                        fill={[
                                            {
                                                match: {
                                                    id: 'ruby'
                                                },
                                                id: 'dots'
                                            },
                                            {
                                                match: {
                                                    id: 'c'
                                                },
                                                id: 'dots'
                                            },
                                            {
                                                match: {
                                                    id: 'go'
                                                },
                                                id: 'dots'
                                            },
                                            {
                                                match: {
                                                    id: 'python'
                                                },
                                                id: 'dots'
                                            },
                                            {
                                                match: {
                                                    id: 'scala'
                                                },
                                                id: 'lines'
                                            },
                                            {
                                                match: {
                                                    id: 'lisp'
                                                },
                                                id: 'lines'
                                            },
                                            {
                                                match: {
                                                    id: 'elixir'
                                                },
                                                id: 'lines'
                                            },
                                            {
                                                match: {
                                                    id: 'javascript',
                                                },
                                                id: 'lines'
                                            }
                                        ]}
                                        legends={[
                                            {
                                                anchor: 'bottom',
                                                direction: 'row',
                                                justify: false,
                                                translateX: 0,
                                                translateY: 56,
                                                itemsSpacing: 0,
                                                itemWidth: 100,
                                                itemHeight: 18,
                                                itemTextColor: '#999',
                                                itemDirection: 'left-to-right',
                                                itemOpacity: 1,
                                                symbolSize: 18,
                                                symbolShape: 'circle',
                                                effects: [
                                                    {
                                                        on: 'hover',
                                                        style: {
                                                            itemTextColor: '#000'
                                                        }
                                                    }
                                                ]
                                            }
                                        ]}
                                    />
                                </div>

                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3} >
                                {
                                    data_tags.map((element, index) => {
                                        return (
                                            <>
                                                <Box>
                                                    <Box className='revenue-card-tags'>{element.tag}</Box>
                                                    <Stack display='flex' flexDirection='row' alignItems='center'>
                                                        <Box className='tags-circle' sx={{ background: `${element.background}` }}></Box>
                                                        <Box className='tags-values'>{element.value}</Box>
                                                    </Stack>
                                                </Box>
                                            </>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>

        </div>
    )
}

