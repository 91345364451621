import { useState, useEffect } from "react";
import "./dataTable.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MaterialReactTable from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv';

const DataTable = ({
  data,
  exportData,
  columns,
}) => {

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportData = () => {
    csvExporter.generateCsv(exportData);
  };

  useEffect(() => {
    console.log('Data', data)
  })
  return (
    <div className="data-table">
      <MaterialReactTable
        enableRowSelection
        className="datagrid"
        data={data}
        columns={columns}
        muiTableHeadCellProps={{
          sx: {
            fontFamily: 'Plus Jakarta Sans',
            fontStyle: 'normal',
            fontSize: '14px',
            fontWeight: '600',
            color: 'rgba(0, 0, 0, 1)',
            '& .MuiBox-root.css-1w86f15':{
              justifyContent:'space-between !important'
            }
          }
        }}
        muiTableBodyCellProps={{
          sx: {
            fontFamily: 'Plus Jakarta Sans',
            fontStyle: 'normal',
            fontSize: '16px',
            color: 'rgba(0, 0, 0, 1)',
          }
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: [5, 10],
          showFirstButton: false,
          showLastButton: false,
        }}

        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
              sx={{background:'rgb(20,128,243) !important',color:"#FFFFFF !important",padding:'6px 16px !important',fontSize:'0.875rem !important',fontWeight:'500 !important',fontFamily:' "Roboto","Helvetica","Arial",sans-serif !important ',borderRadius:'4px !important',boxShadow:'0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'}}
            >
              Export All Data
            </Button>
            {/* <Button
                disabled={
                  !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                }
                //only export selected rows
                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                startIcon={<FileDownloadIcon />}
                variant="contained"
              >
                Export Selected Rows
              </Button> */}
          </Box>
        )}
      />
    </div>
  );
};

export default DataTable;
