import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
  Switch,
  FormGroup,
} from "@mui/material";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Paper } from "@material-ui/core";
import Navbar from "../../components/navbar/Navbar";
import SideBar from "../../components/sidebar/SideBar";
import SnackBar from "../../components/alert/SnackBar";
import "./styles.scss";

import { UPDATE_JOB_BY_ID } from "../../utils/config";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function UpdateJob() {
  const { state } = useLocation();
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
 
  const id = state?.job?._id;
  const [htmlData, setHtmlData] = useState(state?.job?.description);
  const [jobTitle, setJobTitle] = useState(state?.job?.job_title);
  const [position, setPosition] = useState(state?.job?.position);
  const [type, setType] = useState(state?.job?.type);
  const [location, setLocation] = useState(state?.job?.location);
  const [shift, setShift] = useState(state?.job?.shift);
  const [experience, setExperience] = useState(state?.job?.experience);
  const [description, setDescription] = useState(state?.job?.description);
  const [responsibilities, setResponsbilities] = useState(state?.job?.responsibilities);
  const [requirements, setRequirements] = useState(state?.job?.requirements);
  const [startSalaryRange, setStartSalaryRange] = useState("");
  const [endSalaryRange, setEndSalaryRange] = useState("");
  const [numPositions, setNumPositions] = useState(
    state?.job?.number_of_positions
  );
  const [submittedDate, setSubmittedDate] = useState(formatDate(state?.job?.last_date));
  const [status, setStatus] = useState(state?.job?.status);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleShiftChange = (event) => {
    setShift(event.target.value);
  };

  const handleExperienceChange = (event) => {
    setExperience(event.target.value);
  };

  const handleNumPositionChange = (event) => {
    setNumPositions(event.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.checked);
  };

  const updateJob = () => {
    var jobObject = {
      job_title: jobTitle,
      position: position,
      description: description,
      responsibilities:responsibilities,
      requirements:requirements,
      location: location,
      shift: shift,
      status: status,
      last_date: submittedDate,
      number_of_positions: numPositions,
      experience: experience,
      type: type,
      salary_range: startSalaryRange + "-" + endSalaryRange,
    };
    console.log("")
    axios
      .patch(UPDATE_JOB_BY_ID + id, jobObject)
      .then((response) => {
        if (response.error) {
          setOpen(true);
          setMessage(response.error_msg);
          setSeverity("error");
        } else {
          setOpen(true);
          setMessage(response.success_msg);
          setSeverity("success");
          setJobTitle(" ")
          setPosition(" ")
          setLocation(" ")
          setSubmittedDate(" ")
          setNumPositions(" ")
          setStartSalaryRange(" ")
          setEndSalaryRange(" ")
          setDescription(" ")
          setRequirements(" ")
          setResponsbilities(" ")
        }
      })
      .catch(function (error) {
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };

  const validator = () => {
    if (
      jobTitle.length === 0 ||
      position.length === 0 ||
      htmlData.length === 0 ||
      location.length === 0 ||
      shift.length === 0 ||
      numPositions.length === 0 ||
      experience.length === 0 ||
      type.length === 0
    ) {
      alert("Some Fields are missing");
    } else {
      updateJob();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className="box">
      <SideBar />
      <div className="box-container">
        <Navbar />

        <Paper className="form-container">
          <Typography variant="h6" gutterBottom>
            Update Job
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required="required"
                id="position"
                name="job_title"
                label="Job Title"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required="required"
                id="position"
                name="position"
                label="Position"
                value={position}
                onChange={handlePositionChange}
                fullWidth
                autoComplete="given-name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="location"
                name="location"
                label="Location"
                value={location}
                onChange={(event) => {
                  setLocation(event.target.value);
                }}
                fullWidth
                autoComplete
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Shift</InputLabel>
                <Select
                  name="shift"
                  id="demo-simple-select"
                  value={shift}
                  label="Shift"
                  onChange={handleShiftChange}
                >
                  <MenuItem value="Morning">Morning </MenuItem>
                  <MenuItem value="Evening">Evening</MenuItem>
                  <MenuItem value="Night">Night</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Type</InputLabel>
                <Select
                  name="type"
                  value={type}
                  label="type"
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value="OnSite">On-Site</MenuItem>
                  <MenuItem value="Remote">Remote</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Required Experience
                </InputLabel>
                <Select
                  name="experience"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={experience}
                  label="Required Experience"
                  onChange={handleExperienceChange}
                >
                  <MenuItem value="0-1">0-1 years </MenuItem>
                  <MenuItem value="1-2">1-2 years </MenuItem>
                  <MenuItem value="2-3">2-3 years </MenuItem>
                  <MenuItem value="3-4">3-4 years </MenuItem>
                  <MenuItem value="4+">4+ years </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  label="Select Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  value={submittedDate}
                  onChange={(event) => {
                    setSubmittedDate(event.target.value);
                  }}
                />
              </FormControl>

            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="num_positions"
                name="num_positions"
                type={"number"}
                label="Number of Positions"
                value={numPositions}
                onChange={handleNumPositionChange}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type={"number"}
                label="Start Salary Range"
                value={startSalaryRange}
                onChange={(e) => setStartSalaryRange(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                type={"number"}
                label="End Salary Range"
                value={endSalaryRange}
                onChange={(e) => setEndSalaryRange(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} >
              <Typography className="typography textArea" gutterBottom>
                Summary
              </Typography>
              <textarea
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                rows={8}
                placeholder="Enter Summary..."
                className="textarea-field"
              />
              <Typography sx={{ textAlign: "end" }}>At beginning, Write Summary of 2-3 lines.</Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography className="typography textArea" gutterBottom>
                Requirements
              </Typography>
              <textarea
                value={requirements}
                onChange={(event) => setRequirements(event.target.value)}
                rows={8}
                placeholder="Enter Requirements..."
                className="textarea-field"
              />
            </Grid>
            <Grid item xs={12} >
              <Typography className="typography" gutterBottom>
                Responsibilities
              </Typography>
              <textarea
                value={responsibilities}
                onChange={(event) => setResponsbilities(event.target.value)}
                rows={8}
                placeholder="Enter Responsibilities..."
                className="textarea-field"
              />
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '15px' }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Android12Switch
                    onChange={handleStatusChange}
                    value={status}
                    checked={status}
                  />
                }
                label={status ? "Published" : "Archived"}
              />
            </FormGroup>
            <Button
              onClick={validator}
              variant="contained"
              size="medium"
              color="primary"
              sx={{ textTransform: 'none', width: "98px", height: "45px", borderRadius: "6px", padding: "11px 18px", background: "#2D74FF", color: "#FFFFFF", fontFamily: 'Plus Jakarta Sans', fontSize: "18px" }}
            >
              Submit
            </Button>
          </Box>
        </Paper>
        <SnackBar
          open={open}
          severity={severity}
          message={message}
          handleClose={handleClose}
        />
      </div>
    </div >
  );
}
