import React from "react";
import { Stack, Typography, Divider, Link } from "@mui/material";
import "./styles.scss";

export default function About() {
  return (
    <Stack className="email-info">
      <Stack sx={{ padding: "30px 0px" }}>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingBottom: '10px', paddingLeft: '20px' }}>
          <Typography className="email-heading">Skills</Typography>
        </Stack>
        <Link href="#" underline="hover" sx={{ paddingBottom: '20px', paddingLeft: '40px' }}>
          <Typography sx={{ color: '#1480F3' }}>Add Skills</Typography>
        </Link>
        <Divider />
      </Stack>

      <Stack >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingBottom: '10px', paddingLeft: '20px' }}>
          <Typography className="email-heading">Top Specialties</Typography>
        </Stack>
        <Link href="#" underline="hover" sx={{ paddingBottom: '20px', paddingLeft: '40px' }}>
          <Typography sx={{ color: '#1480F3' }} >Add Top Specialties</Typography>
        </Link>
        <Divider />
      </Stack>

      <Stack sx={{ padding: "30px 0px" }}>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingBottom: '10px', paddingLeft: '20px' }}>
          <Typography className="email-heading">Work History</Typography>
        </Stack>
        <Link href="#" underline="hover" sx={{ paddingBottom: '20px', paddingLeft: '40px' }}>
          <Typography sx={{ color: '#1480F3' }}>Add Work History</Typography>
        </Link>
        <Divider />
      </Stack>

      <Stack >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ paddingBottom: '10px', paddingLeft: '20px' }}>
          <Typography className="email-heading">Education</Typography>
        </Stack>
        <Link href="#" underline="hover" sx={{ paddingBottom: '20px', paddingLeft: '40px' }}>
          <Typography sx={{ color: '#1480F3' }} >Add Education</Typography>
        </Link>
        <Divider />
      </Stack>

    </Stack>
  )
}



