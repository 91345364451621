export const BASE_URL = "https://si-nexus-back-end.vercel.app/api/"
// export const BASE_URL = "http://localhost:4000/api/"

//Job URL
export const JOB_BASE_URL = BASE_URL+"job/"
export const ADD_JOB = JOB_BASE_URL+"add_job"
export const GET_JOBS_LIST = JOB_BASE_URL+"get_jobs"
export const GET_JOB_BY_ID = JOB_BASE_URL+""
export const UPDATE_JOB_BY_ID = JOB_BASE_URL+""
export const DELETE_JOB_BY_ID = JOB_BASE_URL+""
export const UPDATE_JOB_STATUS_BY_ID = JOB_BASE_URL+"update_status/"
export const GET_PUBLISHED_JOBS = JOB_BASE_URL+"get_published_jobs/"
export const GET_PUBLISHED_JOBS_COUNT = JOB_BASE_URL+"get_published_jobs_count/"
export const GET_PUBLISHED_JOBS_MONTHLY = JOB_BASE_URL+"get_published_jobs__monthly/"
export const GET_PUBLISHED_JOBS__MONTHLY_COUNT = JOB_BASE_URL+"get_published_jobs_monthly_count/"

//Applied Jobs
export const APPLIED_JOB_BASE_URL = BASE_URL+"apply_job/"
export const ADD_APPLIED_JOB = APPLIED_JOB_BASE_URL+"apply_for_job"
export const GET_APPLIED_JOBS_LIST = APPLIED_JOB_BASE_URL+"get_applied_jobs"
export const UPDATE_APPLIED_JOB_ACCEPTED_STATUS_BY_ID = APPLIED_JOB_BASE_URL+"update_accepted_status/"
export const UPDATE_APPLIED_JOB_REVIEWED_STATUS_BY_ID = APPLIED_JOB_BASE_URL+"update_reviewed_status/"
export const DELETE_AL_DATA= APPLIED_JOB_BASE_URL+"delete_all_data/"

//Employee
export const EMPLOYEE_BASE_URL = BASE_URL+"employee/"
export const ADD_EMPLOYEE = EMPLOYEE_BASE_URL+"add_employee"
export const GET_EMPLOYEES_LIST = EMPLOYEE_BASE_URL+"get_employees"
export const GET_EMPLOYEE_BY_ID = EMPLOYEE_BASE_URL+""
export const UPDATE_EMPLOYEE_BY_ID = EMPLOYEE_BASE_URL+""
export const DELETE_EMPLOYEE_BY_ID = EMPLOYEE_BASE_URL+""
export const UPDATE_EMPLOYEE_STATUS_BY_ID = EMPLOYEE_BASE_URL+"update_status/"

//Blog url
export const BLOG_BASE_URL = BASE_URL+"blog/"
export const ADD_BLOG =  BLOG_BASE_URL +"add_blog"
export const GET_BLOG = BLOG_BASE_URL+"get_blogs"
export const UPDATE_BLOG_BY_ID = BLOG_BASE_URL+""

// Jobs Url
// export const ADD_JOB = BASE_URL+ "/jobs/create"