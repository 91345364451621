import React from "react";
import { Grid,Box} from "@mui/material";
import { Paper } from "@material-ui/core";
import "./styles.scss";
import Navbar from "../../components/navbar/Navbar";
import SideBar from "../../components/sidebar/SideBar";
import ListHeader from "../../components/listHeader/ListHeader";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import PersonalInformation from "../../components/personalInformation/Information";
import Password from "../../components/personalInformation/password";
import Email from "../../components/personalInformation/Email";
import Notification from "../../components/personalInformation/Notification";
import About from "../../components/personalInformation/About"

export default function Setting() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className="box" >
            <SideBar />
            <div className="box-container" >
                <Navbar header={"Settings"}/>
                <ListHeader
                    header={"Dashboard"}
                    header1={"Settings"}
                    firstLinks={"/"}
                    secondLinks={"/settings"}
                />
                <Paper className="form-container" style={{width:'1840px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <Box sx={{ width: '100%', typography: 'body1' }}>
                                <TabContext value={value}>
                                    <Box sx={{ borderBottom: '1px solid #1480F3', borderColor: '#1480F3' }}>
                                        <TabList
                                            onChange={handleChange}
                                            aria-label="lab API tabs example"
                                            sx={{
                                                '& .MuiTab-root.Mui-selected ': {
                                                    background: '#1480F3', // Change the color of the active tab button
                                                    borderRadius: '10px 10px 0px 0px',
                                                    color:'#FFFFFF' // Change the border of the active tab button
                                                },
                                                '& .css-1aquho2-MuiTabs-indicator':{
                                                    display:'none',
                                                }
                                            }}>
                                            <Tab sx={{textTransform:'none',color:'#000000',fontSize:'16px',fontFamily:'Plus Jakarta Sans'}} label="Personal  Information " value="1" />
                                            <Tab sx={{textTransform:'none',color:'#000000',fontSize:'16px',fontFamily:'Plus Jakarta Sans'}} label="Password " value="2" />
                                            <Tab sx={{textTransform:'none',color:'#000000',fontSize:'16px',fontFamily:'Plus Jakarta Sans'}} label="Email" value="3" />
                                            <Tab sx={{textTransform:'none',color:'#000000',fontSize:'16px',fontFamily:'Plus Jakarta Sans'}} label="Notification " value="4" />
                                            <Tab sx={{textTransform:'none',color:'#000000',fontSize:'16px',fontFamily:'Plus Jakarta Sans'}} label="About" value="5" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1" className="tab1">
                                        <PersonalInformation />
                                    </TabPanel>
                                    <TabPanel value="2"><Password /></TabPanel>
                                    <TabPanel value="3"><Email /></TabPanel>
                                    <TabPanel value="4"><Notification /></TabPanel>
                                    <TabPanel value="5"><About /></TabPanel>
                                </TabContext>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </div>
        </div>
    );
}
