import React from "react";
import { Grid, Typography, TextField, Button, } from "@mui/material";
import SunEditor from "suneditor-react";

export default function Information() {
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <Typography className="typography" gutterBottom>
                        First Name
                    </Typography>
                    <TextField
                        required="required"
                        id="position"
                        name="job_title"
                        placeholder="Software Engineer"
                        // value={jobTitle}
                        // onChange={(e) => setJobTitle(e.target.value)}
                        fullWidth
                        autoComplete="given-name"
                        className="custom-textfield"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography className="typography" gutterBottom>
                        Last Name
                    </Typography>
                    <TextField
                        required="required"
                        id="position"
                        name="position"
                        placeholder="Software Engineer"
                        // value={position}
                        // onChange={handlePositionChange}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                        className="custom-textfield"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography className="typography" gutterBottom>
                        User Name
                    </Typography>
                    <TextField
                        required="required"
                        id="position"
                        name="position"
                        placeholder="Software Engineer"
                        // value={position}
                        // onChange={handlePositionChange}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                        className="custom-textfield"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography className="typography" gutterBottom>
                        Email Address
                    </Typography>
                    <TextField
                        required="required"
                        id="position"
                        name="job_title"
                        placeholder="Software Engineer"
                        // value={jobTitle}
                        // onChange={(e) => setJobTitle(e.target.value)}
                        fullWidth
                        // autoComplete="given-name"
                        className="custom-textfield"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography className="typography" gutterBottom>
                        Password
                    </Typography>
                    <TextField
                        required="required"
                        id="position"
                        name="position"
                        placeholder="Software Engineer"
                        // value={position}
                        // onChange={handlePositionChange}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                        className="custom-textfield"
                    />
                </Grid>
                <Grid item xs={12} >
                    <Typography className="typography" gutterBottom>
                        Location
                    </Typography>
                    <TextField
                        required="required"
                        id="position"
                        name="position"
                        placeholder="Software Engineer"
                        // value={position}
                        // onChange={handlePositionChange}
                        fullWidth
                        autoComplete="given-name"
                        variant="outlined"
                        className="custom-textfield"
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography className="typography" gutterBottom>
                        Your Bio
                    </Typography>
                    <SunEditor
                        height={217}
                        setAllPlugins={true}
                        setOptions={{
                            font: [
                                "Poppins",
                                "Arial",
                                "tahoma",
                                "Courier New,Courier",
                                "verdana",
                            ],
                            buttonList: [
                                [
                                    "font",
                                    "fontSize",
                                    "formatBlock",
                                    "bold",
                                    "underline",
                                    "italic",
                                    "strike",
                                    "subscript",
                                    "superscript",
                                    "removeFormat",
                                    "fontColor",
                                    "hiliteColor",
                                    "indent",
                                    "outdent",
                                    "align",
                                    "list",
                                    "horizontalRule",
                                    "table",
                                    "link",
                                    "fullScreen",
                                    "showBlocks",
                                    "codeView",
                                    "undo",
                                    "redo",
                                    "preview",
                                    "print",
                                    "lineHeight",
                                    "paragraphStyle",
                                    "textStyle",
                                ],
                            ],
                        }}
                        name="description"
                    // onChange={handleContentChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12} >
                    <Button
                        // onClick={handleSubmit}
                        size="medium"
                        sx={{ textTransform: 'none', width: '97px', height: '45px', marginRight: '20px', background: '#FFFFFF', color: '#1480F3', border: '1px solid #1480F3', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontWeight: '500', fontFamily: 'Plus Jakarta Sans', }}
                    >
                        Cancel
                    </Button>
                    <Button
                        // onClick={handleSubmit}
                        variant="contained"
                        size="medium"
                        sx={{ textTransform: 'none', width: '79px', height: '45px', background: '#2D74FF', color: '#FFFFFF', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontFamily: 'Plus Jakarta Sans', }}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        </>
    )
}


