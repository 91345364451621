import React, { useState } from "react";
import { Stack, Typography, Button, FormControl, InputAdornment } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import "./styles.scss"

export default function Email() {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <>

            <Stack className="info-password" display="flex" flexDirection='column'  >
                <Stack display="flex" flexDirection='column' >
                    <Typography className="typography" gutterBottom>
                        Old Password
                    </Typography>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Old Password"
                            className="custom-textfield"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
                <Stack display="flex" flexDirection='column' >
                    <Typography className="typography" gutterBottom>
                        New Password
                    </Typography>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder="New Password"
                            className="custom-textfield"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
                <Stack display="flex" flexDirection='column' >
                    <Typography className="typography" gutterBottom>
                        Re-enter New Password
                    </Typography>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? 'text' : 'password'}
                            placeholder=" Re-enter New Password"
                            className="custom-textfield"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Typography className="typography-text">Did you forget your password</Typography>
                    <Stack sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            // onClick={handleSubmit}
                            size="medium"
                            className="cancel-button"
                            sx={{ textTransform: 'none' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            // onClick={handleSubmit}
                            variant="contained"
                            size="medium"
                            sx={{ textTransform: 'none', width: '200px', height: '45px', background: '#2D74FF', color: '#FFFFFF', borderRadius: '6px', textAlign: 'center', fontSize: '18px', fontFamily: 'Plus Jakarta Sans', }}
                        >
                            Change Pasword
                        </Button>
                    </Stack>
                </Stack>
            </Stack>

        </>
    )
}


