import React, { useState, useRef } from "react";
import {
  Grid,
  Stack,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Select,
  MenuItem,
  FormControl,
  Box,
  Switch,
  FormGroup,
  Checkbox,
  Divider,
  ListItemText,
  InputAdornment
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Paper } from "@material-ui/core";
import axios from "axios";
import "./styles.scss";
import Navbar from "../../components/navbar/Navbar";
import SideBar from "../../components/sidebar/SideBar";
import SnackBar from "../../components/alert/SnackBar";
import JobPostingInstructions from "./JobPostingInstructions";
import { ADD_JOB } from "../../utils/config";
import file from "../../assets/images/fileuploader.png"
import ListHeader from "../../components/listHeader/ListHeader";

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

export default function AddJob() {
  const [description,setDescription] = useState("");
  const [responsibilities,setResponsbilities] = useState("");
  const [requirements,setRequirements] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [position, setPosition] = useState("");
  const [type, setType] = useState("OnSite");
  const [location, setLocation] = useState("");
  const [shift, setShift] = useState("Morning");
  const [experience, setExperience] = useState("0-1");
  const [startSalaryRange, setStartSalaryRange] = useState("");
  const [endSalaryRange, setEndSalaryRange] = useState("");
  const [numPositions, setNumPositions] = useState(0);
  const [submittedDate, setSubmittedDate] = useState("");
  const [status, setStatus] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const fileInputRef = useRef(null);

  const options = [
    { value: 'Linkedin', label: 'Linkedin' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Instagram', label: 'Instagram' },
    { value: 'Twitter', label: 'Twitter' },
    { value: 'None', label: 'None' },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleOptionToggle = (value) => () => {
    const currentIndex = selectedOptions.indexOf(value);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(value);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    setSelectedOptions(newSelectedOptions);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(files);
  };

  const handleChooseFiles = () => {
    fileInputRef.current.click();
  };

  const renderSelectedFilesText = () => {
    if (selectedFiles.length === 0) {
      return 'Add File or drop image here';
    } else if (selectedFiles.length === 1) {
      return `Selected file: ${selectedFiles[0].name}`;
    } else {
      return `Selected files: ${selectedFiles.length}`;
    }
  };

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}/${month}/${year}`;

  const handlePositionChange = (event) => {
    setPosition(event.target.value);
  };

  const handleShiftChange = (event) => {
    setShift(event.target.value);
  };

  const handleExperienceChange = (event) => {
    setExperience(event.target.value);
  };


  const handleNumPositionChange = (event) => {
    setNumPositions(event.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.checked);
  };

  const createJob = () => {
    var jobObject = {
      job_title: jobTitle,
      position: position,
      description: description,
      responsibilities:responsibilities,
      requirements:requirements,
      location: location,
      shift: shift,
      status: status,
      last_date: submittedDate,
      number_of_positions: numPositions,
      experience: experience,
      type: type,
      salary_range: startSalaryRange + "-" + endSalaryRange,
    };
    console.log("add job data",jobObject)
    axios
      .post(ADD_JOB, jobObject)
      .then((response) => {
        if (response.error) {
          setOpen(true);
          setMessage(response.error_msg);
          setSeverity("error");
        } else {
          setOpen(true);
          setMessage(response.success_msg);
          setSeverity("success");
          setJobTitle("");
          setPosition("");
          setType("");
          setStartSalaryRange("") ;
          setEndSalaryRange('')
          setLocation("");
          setShift("");
          setExperience("");
          setNumPositions("");
         
        }
      })
      .catch(function (error) {
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };

  const validator = () => {
    if (
      jobTitle.length === 0 ||
      position.length === 0 ||
      description.length === 0 ||
      responsibilities.length === 0 ||
      requirements.length === 0 ||
      location.length === 0 ||
      shift.length === 0 ||
      numPositions.length === 0 ||
      experience.length === 0 ||
      type.length === 0
    ) {
      alert("Some Fields are missing");
    } else {
      createJob();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className="box">
      <SideBar />
      <div className="box-container">
        <Navbar header={"Jobs"} />
        <ListHeader
          header={"Dashboard"}
          header1={"Jobs"}
          header2={"Add Jobs"}
          firstLinks={"/"}
          secondLinks={"/jobs"}
        />
      
        <Paper className="form-container" >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography className="typography" gutterBottom>
                Job Title *
              </Typography>
              <TextField
                required="required"
                id="position"
                name="job_title"
                placeholder="Software Engineer"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
                fullWidth
                autoComplete="given-name"
                className="custom-textfield"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="typography" gutterBottom>
                Position
              </Typography>
              <TextField
                required="required"
                id="position"
                name="position"
                placeholder="Software Engineer"
                value={position}
                onChange={handlePositionChange}
                fullWidth
                autoComplete="given-name"
                variant="outlined"
                className="custom-textfield"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="typography" gutterBottom>
                Location *
              </Typography>
              <TextField
                required
                id="location"
                name="location"
                placeholder="Lahore"
                value={location}
                onChange={(event) => {
                  setLocation(event.target.value);
                }}
                fullWidth
                autoComplete
                variant="outlined"
                className="custom-textfield"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="typography" gutterBottom>
                Shift
              </Typography>
              <FormControl fullWidth className="custom-textfield">
                <Select
                  name="shift"
                  id="demo-simple-select"
                  value={shift}
                  onChange={handleShiftChange}
                >
                  <MenuItem value="Morning">Morning </MenuItem>
                  <MenuItem value="Evening">Evening</MenuItem>
                  <MenuItem value="Night">Night</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="typography" gutterBottom>
                Type
              </Typography>
              <FormControl fullWidth className="custom-textfield">
                <Select
                  name="type"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <MenuItem value="OnSite">On-Site</MenuItem>
                  <MenuItem value="Remote">Remote</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="typography" gutterBottom>
                Required Experience
              </Typography>
              <FormControl fullWidth className="custom-textfield" >
                <Select
                  name="experience"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={experience}
                  onChange={handleExperienceChange}
                >
                  <MenuItem value="0-1">0-1 years </MenuItem>
                  <MenuItem value="1-2">1-2 years </MenuItem>
                  <MenuItem value="2-3">2-3 years </MenuItem>
                  <MenuItem value="3-4">3-4 years </MenuItem>
                  <MenuItem value="4+">4+ years </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="typography" gutterBottom>
                Date
              </Typography>
              <FormControl fullWidth className="custom-textfield">
                <TextField
                  type="date"
                  defaultValue={currentDate}
                  onChange={(event) => {
                    setSubmittedDate(event.target.value);
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="typography" gutterBottom>
                Number of Position *
              </Typography>
              <TextField
                required
                id="num_positions"
                name="num_positions"
                type={"number"}
                value={numPositions}
                onChange={handleNumPositionChange}
                fullWidth
                variant="outlined"
                className="custom-textfield"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography className="typography" gutterBottom>
                    Salary Range *
                  </Typography>
                  <TextField
                    required
                    type={"number"}
                    value={startSalaryRange}
                    onChange={(e) => setStartSalaryRange(e.target.value)}
                    fullWidth
                    variant="outlined"
                    className="custom-textfield"
                  />

                </Grid>
                <Grid item xs={6}>
                  <Typography className="typography" sx={{ visibility: "hidden" }} gutterBottom>
                    End Salary Range
                  </Typography>
                  <TextField
                    required
                    shrink
                    type={"number"}
                    value={endSalaryRange}
                    onChange={(e) => setEndSalaryRange(e.target.value)}
                    fullWidth
                    variant="outlined"
                    className="custom-textfield"
                  />

                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="typography textArea" gutterBottom>
                Schedule Publish Date
              </Typography>
              <FormControl fullWidth className="custom-textfield">
                <TextField
                  type="date"
                  defaultValue={currentDate}
                  onChange={(event) => {
                    // setSubmittedDate(event.target.value);
                  }}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <Stack direction='row'>
                  <Checkbox
                    color="primary"
                  />
                  <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}> Immediately Publish</Typography>
                </Stack>
              </FormControl>
            </Grid>
            <Grid item xs={12} >
              <Typography className="typography textArea" gutterBottom>
                Summary
              </Typography>
              <textarea
                // value={value}
                onChange={(event)=>setDescription(event.target.value)}
                rows={8}
                placeholder="Enter Summary..."
                className="textarea-field"
              />
              <Typography sx={{ textAlign: "end" }}>At beginning, Write Summary of 2-3 lines.</Typography>
            </Grid>
            <Grid item xs={12} >
              <Typography className="typography textArea" gutterBottom>
                Requirements
              </Typography>
              <textarea
                // value={value}
                onChange={(event)=>setRequirements(event.target.value)}
                rows={8}
                placeholder="Enter Requirements..."
                className="textarea-field"
              />
            </Grid>
            <Grid item xs={12} >
              <Typography className="typography" gutterBottom>
                Responsibilities
              </Typography>
              <textarea
                // value={value}
                onChange={(event)=>setResponsbilities(event.target.value)}
                rows={8}
                placeholder="Enter Responsibilities..."
                className="textarea-field"
              />
              <Divider sx={{ paddingTop: '10px' }} />
            </Grid>
          </Grid>

          <Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Android12Switch
                    onChange={handleStatusChange}
                    value={status}
                  />
                }
                label={status ? "Published" : "Archived"}
              />
            </FormGroup>
          </Box>

          <Box display={"flex"} justifyContent="space-between" >
            <Box >
              <Typography>Social  Media</Typography>
              <Select
                multiple
                value={selectedOptions}
                onChange={(event) => setSelectedOptions(event.target.value)}
                endAdornment={<InputAdornment position="start" sx={{ position: 'absolute', left: "15px" }}>All</InputAdornment>}
                renderValue={() => null} // Hides the selected options from being displayed in the field
                style={{
                  width: '527px',
                  height: '60px',
                  borderRadius: '10px'
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <ListItemText primary={option.label} />
                    <Checkbox checked={selectedOptions.indexOf(option.value) !== -1} onChange={handleOptionToggle(option.value)} />
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box sx={{ width: "527px", height: "60px" }}>
              <Typography>Upload File</Typography>
              <div style={{ width: "100%", height: "100%", border: "1px dashed rgba(196, 202, 205, 0.4)", borderRadius: "10px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={file} alt="" onClick={handleChooseFiles} />
                <input
                  accept="image/*"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                />
                <label htmlFor="file-input" onClick={handleChooseFiles}>
                  {renderSelectedFilesText()}
                </label>
              </div>
            </Box>

          </Box>

          <Box display={"flex"} justifyContent="flex-end" sx={{ paddingTop: "30px" }}>
            <Button
              onClick={validator}
              variant="contained"
              size="medium"
              color="primary"
              sx={{ textTransform:'none',width: "98px", height: "45px", borderRadius: "6px", padding: "11px 18px", background: "#2D74FF", color: "#FFFFFF", fontFamily: 'Plus Jakarta Sans', fontSize: "18px" }}
            >
              Submit
            </Button>
          </Box>
        </Paper>
        <SnackBar
          open={open}
          severity={severity}
          message={message}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}
