import React from "react";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/Navbar";

import ContactTable from "../../components/tables/ContactTable";
import ListHeader from "../../components/listHeader/ListHeader";

export default function GetContactList() {
  return (
    <div className="list">
      <Sidebar />
      <div className="list-container">
        <Navbar header={"Contacts"} />
        <ListHeader header={"Dashboard"} header1={"Client Contacts"} firstLinks={"/"} />
        <ContactTable />
      </div>
    </div>
  );
}
