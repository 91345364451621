import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/Navbar";
import ListHeader from "../../components/listHeader/ListHeader";
import "./employee.scss";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
export default function Leave() {
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [selectedApprover, setSelectedApprover] = useState("");
    const [status, setStatus] = useState("");
    const [leaveType, setLeaveType] = useState("");
    const [leaveNumber, setLeaveNumber] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [reason, setReason] = useState("");
    const [employeeList, setEmployeeList] = useState([]);
  
    useEffect(() => {
      fetch("https://si-nexus-back-end.vercel.app/api/employee/get_employees")
        .then((response) => response.json())
        .then((employeedata) => setEmployeeList(employeedata.employees))
        .catch((error) => console.error("Error fetching data:", error));
    }, []);
  
    const clearForm = () => {
        console.log("Clearing the form");
      setSelectedEmployee("");
      setSelectedApprover("");
      setLeaveType("");
      setFromDate("");
      setToDate("");
      setReason("");
      setLeaveNumber("");
      setTimeout(() => {
       alert("leave submit successfully")
      }, 1000);
    };
  
    const handleApplyLeave = () => {
        
        const leaveData = {
          employee_name: selectedEmployee, 
          approved_by:selectedApprover,
          leave_type: leaveType, 
          number_of_days: leaveNumber,
          from_date: fromDate,
          to_date: toDate,
          reason:reason,
          status:status,
        };
      
       
        fetch("https://si-nexus-back-end.vercel.app/api/leave/add_leave", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(leaveData),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("Leave added successfully:", data);
           
            clearForm();
          })
          .catch((error) => {
            console.error("Error adding leave:", error);
          });
      };
      
  

  return (
    <div className="list">
      <Sidebar />
      <div className="list-container">
        <Navbar header={"Employee Leave"} />
        <ListHeader
          header={"Dashboard"}
          header1={"Leave Request"}
          firstLinks={"/"}
          secondLinks={"/leave"}
        />

        <div className="leave-form">
        <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <InputLabel style={{ marginLeft:'2vw'}} htmlFor="employee-select">Employee Name</InputLabel>
            <Select
              id="employee-select"
              style={{
                width: "32vw",
                height: "6vh",
                border: "none",
                borderRadius: "10px",
                marginTop: "10px",
                paddingLeft: "10px",
                color: "black",
                marginLeft:'2vw'
              }}
              value={selectedEmployee}
              onChange={(e) => setSelectedEmployee(e.target.value)}
            >
              <MenuItem value="">
                <em>Select an employee</em>
              </MenuItem>
              {Array.isArray(employeeList) &&
                employeeList.map((employee) => (
                  <MenuItem
                    key={employee._id}
                    value={employee.first_name + " " + employee.last_name}
                  >
                    {employee.first_name + " " + employee.last_name}
                  </MenuItem>
                ))}
            </Select>
       </Grid>
       <Grid item xs={12} sm={6}>
        
            <InputLabel  htmlFor="approver-select">Approved by</InputLabel>
            <Select
              id="approver-select"
              style={{
                width: "32vw",
                height: "6vh",
                border: "none",
                borderRadius: "10px",
                marginTop: "10px",
                paddingLeft: "10px",
                color: "black",
               
              }}
              value={selectedApprover}
              onChange={(e) => setSelectedApprover(e.target.value)}
            >
              <MenuItem value="">
                <em>Select an approver</em>
              </MenuItem>
              <MenuItem value="Brirra Fayyaz">Brirra Fayyaz</MenuItem>
              <MenuItem value="Haris Mehar">Haris Mehar</MenuItem>
              <MenuItem value="Kalbe Abbas">Kalbe Abbas</MenuItem>
              <MenuItem value="Furqan Bhatti">Furqan Bhatti</MenuItem>
            </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
           
            <InputLabel style={{ marginLeft:'2vw'}}   htmlFor="approver-select">Leave Type</InputLabel>
            <Select
              id="leave_type"
              style={{
                width: "32vw",
                height: "6vh",
                border: "none",
                borderRadius: "10px",
                marginTop: "10px",
                paddingLeft: "10px",
                color: "black",
                marginLeft:'2vw'
              }}
              value={leaveType}
              onChange={(e) => setLeaveType(e.target.value)}
            >
              <MenuItem value="">
                <em>Select leave Type</em>
              </MenuItem>
              <MenuItem value="Sick Leave">Sick Leave</MenuItem>
              <MenuItem value="Personal Work">Personal Work</MenuItem>
              <MenuItem value="Marriage leave">Marriage leave</MenuItem>
            </Select>
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel  htmlFor="approver-select">Number of leave</InputLabel>
              <TextField
                required
                type="number"
                sx={{
                    width: "32vw",
                    height: "6vh",
                    border: "none",
                    borderRadius: "20px",
                    marginTop: "10px",
                    paddingLeft: "10px",
                    color: "black",
                  }}
                  value={leaveNumber}
                  onChange={(e) => setLeaveNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel style={{ marginLeft:'2vw'}}   htmlFor="approver-select">From Date</InputLabel>
            <input type="date" style={{ width: "32vw",
                    height: "6vh",
                    border: "0.1px solid grey",
                    borderRadius: "10px",
                    marginTop: "10px",
                    paddingLeft: "10px",
                    color: "black",
                    marginLeft:'2vw'
                    }}
                    
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    />
        
            </Grid>
            <Grid item xs={12} sm={6}>
            <InputLabel htmlFor="approver-select">To Date</InputLabel>
            <input type="date" style={{ width: "32vw",
                    height: "6vh",
                    border: "0.1px solid grey",
                    borderRadius: "10px",
                    marginTop: "10px",
                    paddingLeft: "10px",
                    color: "black",
                    }}
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    
                    />
        
            </Grid>
            <Grid item xs={12} sm={6}>
        
            <InputLabel  style={{ marginLeft:'2vw'}}   htmlFor="status-select">Status</InputLabel>
            <Select
              id="status"
              style={{
                width: "65.5vw",
                height: "6vh",
                border: "none",
                borderRadius: "10px",
                marginTop: "10px",
                paddingLeft: "10px",
                color: "black",
                marginLeft:'2vw'
              }}
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value="">
                <em>Select a status</em>
              </MenuItem>
              <MenuItem value="Pending">Pending</MenuItem>
              <MenuItem value="Approved">Approved</MenuItem>
              <MenuItem value="Rejected">Rejected</MenuItem>
            </Select>
            </Grid>
            <Grid item xs={12} sm={12}>
            <InputLabel style={{ marginLeft:'2vw'}} htmlFor="approver-select">Reason/Subject</InputLabel>
              <TextField
                required
                multiline
                rows={3}
                placeholder="Enter minimum 120 Characters"
                sx={{
                    width: "65.5vw",
                    height: "6vh",
                    border: "none",
                    
                    
                    borderRadius: "20px",
                    marginTop: "10px",
                    paddingLeft: "10px",
                    color: "black",
                    marginLeft:'2vw'
                  }}
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            
        
            </Grid>
            <Grid item xs={12} sm={6}>
            <button   style={{marginLeft:'23vw',marginTop:'10vh'}} className="modal-btn-2"   onClick={handleApplyLeave}>
              Apply Leave
            </button>
          </Grid>
           
            </Grid>
        </div>

       
      </div>
    </div>
  );
}
