import React from "react";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/Navbar";
import JobsTable from "../../components/tables/JobsTable";
import ListHeader from "../../components/listHeader/ListHeader";

export default function GetJobList() {
  return (
    <div className="list">
      <Sidebar />
      <div className="list-container">
        <Navbar header={"Jobs"} />
        <ListHeader
          header={"Dashboard"}
          header1={"Jobs List"}
          firstLinks={"/"}
          firstButton={true}
          firstButtonText={"Add New Job"}
          firstLink={"/jobs/add"}
        />
        <JobsTable />
      </div>
    </div>
  );
}
