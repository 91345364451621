import React, { useEffect, useState } from "react";
import DataTable from "../../components/dataTable/DataTable";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./dataTable.scss";
import axios from "axios";
import { GET_JOBS_LIST } from "../../utils/config";
import { apiDELETE, apiGET, jobsApiPATCH, apiPOST, apiPUT } from "../../utils/api";
import DeleteIcon from "../../assets/images/delete.svg"
import EditIcon from "../../assets/images/edit.svg"
import { FormGroup, FormControlLabel, Switch } from "@material-ui/core";

export default function JobsTable() {
  const [jobs, setJobs] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");
  const [filterJobs, setFilerJobs] = useState("");
  const navigate = useNavigate();

  const handleStatusChange = (event, params) => {
    const { _id } = params.row.original;
    var obj = {};
    if (params.row.original.status) {
      obj = { status: false };

      jobsApiPATCH("job/update_status", obj, _id)
        .then((response) => {
          params.row.original.status = false;
          getJobsList();
          if (response.data.error) {
            console.log(response.data.error_message)
            console.log("1")
          } else {
            console.log("2", params.row.original.status)
          }
        })
        .catch((err) => {
          console.log(err);

        });
    } else {
      obj = { status: true }
      jobsApiPATCH("job/update_status", obj, _id)
        .then((response) => {
          params.row.original.status = false;
          getJobsList();
          if (response.data.error) {
            console.log(response.data.error_message)
            console.log("3")
          } else {
            params.row.original.status = true;
            console.log("4")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  useEffect(() => {
    getJobsList();
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  const getJobsList = () => {
    axios
      .get(GET_JOBS_LIST)
      .then((res) => {
        setJobs(res.data.jobs);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  const handleDelete = (id) => {
    apiDELETE("job", id)
      .then((res) => {
        if (res.data.status === true) {
          setStatusMessage(res.data.message);
          const posts = filterJobs?.filter((item) => item._id !== id);
          setFilerJobs(posts);
          setTimeout(() => {
            setStatusMessage("");
          }, 3000);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUpdate = (row) => {
    navigate("/jobs/updatejob", { state: { job: row } });
  };

  const exportColumns =[
    {
      accessorKey: "row",
      header: "Sr#.",
      size: 230,
    },
    {
      accessorKey: "job_title",
      header: "Job Title",
      size: 230,
    },
    {
      accessorKey: "position",
      header: "Position",
      size: 230,
    },
    {
      accessorKey: "location",
      header: "Location",
      size: 230,
    },
    {
      accessorKey: "shift",
      header: "Shift",
      size: 160,
    },
    {
      accessorKey: "createdAt",
      header: "Last Date",
      size: 230,
    },
    {
      accessorKey: "number_of_positions",
      header: "# Of Position(s)",
      size: 150,
    },
    {
      accessorKey: "type",
      header: "Type",
      size: 150,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 160,
    },
  ]

  const jobsColumns = [
    {
      accessorKey: "row",
      header: "Sr#.",
      size: 100,
      Cell: (params) => {
        return (
          <>
            <div>{parseFloat(params.row.id) + 1}</div>
          </>
        );
      },
    },
    {
      accessorKey: "job_title",
      header: "Job Title",
      size: 230,
    },
    {
      accessorKey: "position",
      header: "Position",
      size: 230,
    },
    {
      accessorKey: "location",
      header: "Location",
      size: 140,
    },
    {
      accessorKey: "shift",
      header: "Shift",
      size: 140,
    },
    {
      accessorKey: "last_date",
      header: "Last Date",
      size: 140,
      Cell: (params) => {
        return (
          <>
            <div>{formatDate(params.row.original.last_date)}</div>
          </>
        );
      },
    },
    {
      accessorKey: "number_of_positions",
      header: "# Of Position(s)",
      size: 140,
      Cell: (params) => {
        return (
          <>
            <div style={{textAlign:'center'}}>{params.row.original.number_of_positions}</div>
          </>
        );
      },
    },
    {
      accessorKey: "type",
      header: "Type",
      size: 140,
    },
    {
      accessorKey: "status",
      header: "Status",
      size: 160,
      Cell: (params) => {
        console.log("jobs Status Params", params)
        return (
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {params.row.original.status === true ? (
              <div className={`cell-with-status active`}>{"Published"}</div>
            ) : (
              <div className={`cell-with-status passive`}>{"Archive"}</div>
            )}

            <FormGroup style={{ paddingLeft: '20px' }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={params.row.original.status}
                    color="primary"
                    onChange={(event) => {
                      handleStatusChange(event, params)
                    }}
                  />
                }
              />
            </FormGroup>
          </div>
        );
      },
    },
    {
      accessorKey: "action",
      header: "Action",
      size: 140,
      Cell: (params) => {
        return (
          <div className="cell-action">
            <IconButton
              aria-label="delete"
              size="medium"
              onClick={() => handleDelete(params.row.original._id)}
            >
              <img src={DeleteIcon} alt="delete" width="17px" height="22.17px" />
            </IconButton>

            <IconButton
              aria-label="edit"
              size="medium"
              onClick={() => handleUpdate(params.row.original)}
            >
              <img src={EditIcon} alt="delete" width="21px" height="21.98px" />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return (
    <>
      <DataTable
        data={jobs}
        exportData={exportColumns}
        columns={jobsColumns}
      />
    </>
  );
}
