import React, { useState ,useEffect} from "react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Alert,
  Box,
  Input,
  Autocomplete,
} from "@mui/material";
import { apiGET } from "../../utils/api";
import { Paper } from "@material-ui/core";
import Navbar from "../../components/navbar/Navbar";
import SideBar from "../../components/sidebar/SideBar";
import axios from 'axios';
import SunEditor from "suneditor-react";
import { useLocation } from "react-router-dom";
import {
    UPDATE_BLOG_BY_ID,
} from "../../utils/config";

export default function UpdateBlog() {
    const { state } = useLocation();
    console.log("states",state)
  const [htmlData, setHtmlData] = useState(state?.job?.description);
  const [title, setTitle] = useState(state?.job?.title);
  const [selectedFile, setSelectedFile] = useState(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategory, setSelectedcategory] = useState("");
  const [selectedCategoryValue, setSelectedcategoryValue] = useState(null);
 
  const [availableTags, setAvailableTags] = useState([]);
  const id = state?.job?._id;
  //const fileInputRef = useRef(null);
  const onChange = (content) => {
    setHtmlData(content);
  };
  const [fileInputKey, setFileInputKey] = useState(Date.now().toString()); // Initialize the file input key

  const updateblog = async () => {
    try {
      const blogFormData = new FormData();
      var data = {
        title: title,
        description: htmlData,
        categories: selectedCategoryValue.name,
        tags:selectedTags
      };
      blogFormData.append('image', selectedFile); 
      blogFormData.append("data", JSON.stringify(data));
      const customConfig = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const response = await axios.patch(UPDATE_BLOG_BY_ID +id, blogFormData, customConfig);
      const responseData = response.data;
    
      if (responseData.error === false) {
        setStatusMessage(responseData.success_msg);
        setHtmlData(Date.now().toString());
        setTitle('');
        setSelectedFile(null);
        setSelectedTags([]); // Reset selectedTags to an empty array
        setSelectedcategoryValue({name:''});
        setFileInputKey(Date.now().toString());
        setTimeout(() => {
          setStatusMessage('');
        }, 3000);
      } else {
        setStatusMessage(responseData.error_msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (state?.blog?.description) {
      setHtmlData(state.blog.description);
    }
    if (state?.blog?.image) {
        setSelectedFile(state.blog.image);
      }
  }, [state]);

  const handleEditorLoad = () => {
    setIsEditorReady(true);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  
  useEffect(() => {
    fetchTagsFromDB();
    fetchCategoryFromDB();
  }, []);

    // Fetch available tags from the database or API
    const fetchTagsFromDB = async () => {
      try {
        // Your API call or database fetch to get the available tags
        const response = await apiGET("tags/get_tags");
        const tags = response.data.tags;
        setAvailableTags(tags);
      } catch (error) {
        console.error("Error fetching tags:", error);
      }
    };

  const handleTagChange = (event, values) => {
    setSelectedTags(values);
  };

  console.log("selectags",selectedTags)
  const fetchCategoryFromDB = async () => {
    try {
      const response = await apiGET("category/get_categorys");
      setSelectedcategory(response.data.categories);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  return (
    <div className="box">
      <SideBar />
      <div className="box-container">
        <Navbar />

        <Paper className="form-container">
          <Typography variant="h6" gutterBottom>
            Update Blog
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                id="title"
                name="title"
                label="Title"
                value={title}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
                fullWidth
                autoComplete="given-name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Input
                required
                id="file"
                name="file"
                key={fileInputKey}
                variant="outlined"
                fullWidth
                onChange={handleFileInputChange}
                type="file"
                placeholder="Choose Blog Thumbnail"
              inputProps={{
                  accept:"image/*"}
                }
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <SunEditor
               defaultValue={htmlData}
               onLoad={handleEditorLoad}
                setAllPlugins={true}
                key={fileInputKey}
                setOptions={{
                  font: [
                    "Poppins",
                    "Arial",
                    "tahoma",
                    "Courier New,Courier",
                    "verdana",
                  ],
                  buttonList: [
                    [
                      "font",
                      "fontSize",
                      "bold",
                      "underline",
                      "italic",
                      "strike",
                      "subscript",
                      "superscript",
                      "removeFormat",
                      "fontColor",
                      "hiliteColor",
                      "indent",
                      "outdent",
                      "align",
                      "list",
                      "horizontalRule",
                      "table",
                      "link",
                      "image",
                      "video",
                      "audio",
                      "fullScreen",
                      "showBlocks",
                      "codeView",
                      "undo",
                      "redo",
                      "preview",
                      "print",
                      "lineHeight",
                      "paragraphStyle",
                      "textStyle",
                    ],
                  ],
                }}
                id="description"
                name="description"
                onChange={onChange}
              />

            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                multiple
                options={availableTags}
                getOptionLabel={(tag) => tag.name}
                value={selectedTags}
                onChange={handleTagChange}
                renderInput={(params) => (
                  <TextField {...params} label="Tags" variant="outlined" />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={selectedCategory}
                getOptionLabel={(category) => category.name}
                value={selectedCategoryValue}
                onChange={(event, newInputValue) => {
                  setSelectedcategoryValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Category" />
                )}
                renderOption={(props, category) => (
                  <Box component="li" {...props} key={category._id}>
                    {category.name}
                  </Box>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Box>
                {statusMessage ? (
                  <Alert severity="success">{statusMessage}</Alert>
                ) : (
                  ""
                )}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Grid
                justifyContent={"flex-start"}
                container
                spacing={1}
                direction={"row"}
              >
                <Grid item>
                  <Button
                    onClick={updateblog}
                    variant="contained"
                    size="medium"
                    color="primary"
                    sx={{textTransform:'none',width:'79px',height:'45px',background:'#2D74FF',color:'#FFFFFF',borderRadius:'6px',padding:'11px 18px',fontSize:'18px',fontFamily:'Plus Jakarta Sans',}}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
}
