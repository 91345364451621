import React from "react";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/Navbar";
import BlogsTable from "../../components/tables/BlogsTable ";
import ListHeader from "../../components/listHeader/ListHeader";

export default function GetBlogList() {
  return (
    <div className="list">
      <Sidebar />
      <div className="list-container">
        <Navbar header={"Blogs"} />
        <ListHeader
          header={"Dashboard"}
          header1={"Blogs Listing"}
          firstLinks={"/"}
          firstButton={true}
          firstButtonText={"Add New Blog"}
          firstLink={"/blogs/add"}
        />
        <BlogsTable />
      </div>
    </div>
  );
}
