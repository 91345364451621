import React, { useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Paper } from "@material-ui/core";
import Alert from "@mui/material/Alert";
import { Button } from "@mui/material";
import Navbar from "../../components/navbar/Navbar";
import SideBar from "../../components/sidebar/SideBar";
import { ADD_EMPLOYEE } from "../../utils/config";
import SnackBar from "../../components/alert/SnackBar";

export default function AddEmployee() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  //   const [status, setStatus] = useState(true);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  var employee = {
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    address: "",
  };

  const addEmployee = () => {
    employee = {
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      email: email,
      address: address,
    };
    axios
      .post(ADD_EMPLOYEE, employee)
      .then(function (response) {
        if (response.data.error) {
          console.log(response.data);
          setOpen(true);
          setMessage(response.data.error_msg);
          setSeverity("error");
        } else {
          console.log(response);
          setOpen(true);
          setMessage(response.data.success_msg);
          setSeverity("success");
          setFirstName("");
          setLastName("");
          setPhone("");
          setAddress("");
          setEmail("");
        }
      })
      .catch(function (error) {
        console.log("error: " + error);
        setOpen(true);
        setMessage("error: " + error);
        setSeverity("error");
      });
  };

  const validation = () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      phone.length === 0 ||
      address.length === 0 ||
      email.length === 0
    ) {
      <Alert severity="error">Some Fields are missing</Alert>;
    } else {
      addEmployee();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <div className="box">
      <SideBar />
      <div className="box-container">
        <Navbar />
        <Paper className="form-container">
          <Typography variant="h6" gutterBottom>
            Add Employee
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="First Name"
                fullWidth
                variant="outlined"
                value={firstName}
                onChange={(event) => setFirstName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Last Name"
                fullWidth
                variant="outlined"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Email"
                fullWidth
                variant="outlined"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                label="Phone"
                fullWidth
                variant="outlined"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="address"
                name="address"
                label="Address"
                fullWidth
                variant="outlined"
                value={address}
                onChange={(event) => setAddress(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <FormControlLabel
                control={
                  <Checkbox color="secondary" name="status" value="false" />
                }
                label="Status"
                value={status}
                onChange={(event) => setStatus(event.target.value)}
              /> */}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid
                justifyContent={"flex-end"}
                container
                spacing={1}
                direction={"row"}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => validation()}
                    color="primary"
                    sx={{textTransform:'none',width:'79px',height:'45px',background:'#2D74FF',color:'#FFFFFF',borderRadius:'6px',padding:'11px 18px',fontSize:'18px',fontFamily:'Plus Jakarta Sans',}}
                  >
                    Save
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                   variant="contained" 
                   size="medium" 
                   color="primary"
                   sx={{ textTransform: 'none', width: '97px', height: '45px', marginRight: '20px', background: '#FFFFFF', color: '#1480F3', border: '1px solid #1480F3', borderRadius: '6px', padding: '11px 18px', textAlign: 'center', fontSize: '18px', fontWeight: '500', fontFamily: 'Plus Jakarta Sans', }}
                   >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <SnackBar
          open={open}
          severity={severity}
          message={message}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
}
