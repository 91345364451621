import React, { useState } from "react";
import './navbar.scss'
import { Stack, Divider, Avatar, InputAdornment, TextField } from '@mui/material';
import message from "../../assets/images/message.png";
import notification from "../../assets/images/notification.svg";
import search from "../../assets/images/search.png";

export default function Navbar({ header }) {
  return (
    <Stack direction="row" justifyContent="space-between" className="navbar">
      <Stack direction="row" alignItems="center" sx={{ paddingLeft: "32px" }} ><p className="nav-text">{header}</p></Stack>
      <Stack direction="row" spacing={4} alignItems="center" sx={{ paddingRight: "55px" }}>
        {/* <TextField
          variant="outlined"
          placeholder="Search ..."
          sx={{
            '& input': {
              padding: '0',
              width: "220px",
              height: "40px"
            },
            '& ::placeholder': {
              fontFamily: 'Plus Jakarta Sans',
              fontSize: "12px",
              fontWeight: 500,
              color: "rgba(25, 32, 49, 0.4);"
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img src={search} alt="Custom Image" style={{ width: '16px', height: '16px' }} />
              </InputAdornment>
            ),
          }}
        /> */}
        {/* <Divider orientation="vertical" className="divider" />
        <img src={message} alt="" className="messages" />
        <img src={notification} alt="" className="notifications" /> */}
        <Avatar alt="Aqib" src="/static/images/avatar/1.jpg" style={{ width: 30, height: 30 }} />
        <p className="profile-name">Aqib Malik</p>
      </Stack>
    </Stack>
  );
}
