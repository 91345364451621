import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from "@mui/x-data-grid";
import Sidebar from "../../components/sidebar/SideBar";
import Navbar from "../../components/navbar/Navbar";
import ListHeader from "../../components/listHeader/ListHeader";
import Close from "../../assets/images/close.png";
import "./employee.scss";
import { ExportToCsv } from 'export-to-csv';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export default function Attendance() {
 
  const [showWinModal, setShowWinModal] = useState(false);
  const [filterType, setFilterType] = useState(""); 
  const [filterName, setFilterName] = useState("");
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date()); 
  

  useEffect(() => {
    getAttendance();
  }, []);

  const getAttendance = async () => {
    try {
      const response = await axios.get(
        "https://si-nexus-back-end.vercel.app/api/attendance/get_all_attendance"
      );
      setFilteredData(response.data.response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatusChange = async (event, employeeId) => {
    const newStatus = event.target.value;
    try {
      await axios.patch(
        `https://si-nexus-back-end.vercel.app/api/attendance/${employeeId}`,
        {
          status: newStatus,
        }
      );
      const updatedData = filteredData.map((item) =>
        item._id === employeeId ? { ...item, status: newStatus } : item
      );

      setFilteredData(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const updateCheckinTime = async (Id, newValue) => {
    console.log("Updating checkin_time with new value:", newValue);

    try {
      await axios.patch(
        `https://si-nexus-back-end.vercel.app/api/attendance/${Id}`,
        {
          checkin_time: newValue,
        }
      );

      console.log("Checkin_time updated successfully.");

      const updatedData = filteredData.map((item) =>
        item._id === Id ? { ...item, checkin_time: newValue } : item
      );

      setFilteredData(updatedData);

      console.log("Data updated:", updatedData);
    } catch (error) {
      console.log("Error updating checkin_time:", error);
    }
  };

  const updateCheckoutTime = async (employeeId, newValue2) => {
    console.log("Updating checkout_time with new value:", newValue2);

    try {
      await axios.patch(
        `https://si-nexus-back-end.vercel.app/api/attendance/${employeeId}`,
        {
          checkout_time: newValue2,
        }
      );

      console.log("Checkout_time updated successfully.");

      const updatedData = filteredData.map((item) =>
        item._id === employeeId ? { ...item, checkout_time: newValue2 } : item
      );

      setFilteredData(updatedData);

      console.log("Data updated:", updatedData);
    } catch (error) {
      console.log("Error updating checkout_time:", error);
    }
  };

  function calculateTotalTime(checkinTime, checkoutTime) {
    if (!checkinTime || !checkoutTime) {
      return "";
    }

    const [checkinHours, checkinMinutes] = checkinTime.split(":").map(Number);
    const [checkoutHours, checkoutMinutes] = checkoutTime
      .split(":")
      .map(Number);

    if (
      isNaN(checkinHours) ||
      isNaN(checkinMinutes) ||
      isNaN(checkoutHours) ||
      isNaN(checkoutMinutes)
    ) {
      return "Invalid time format";
    }

    const checkin = new Date(1970, 0, 1, checkinHours, checkinMinutes);
    const checkout = new Date(1970, 0, 1, checkoutHours, checkoutMinutes);

    const timeDifference = checkout - checkin;

    const hours = Math.floor(timeDifference / 3600000);
    const minutes = Math.floor((timeDifference % 3600000) / 60000);

    return `${hours}h ${minutes}m`;
  }
  const greenIconStyle = {
    color: 'green',
  };
  const employeeColumns = [
    {
      field: "rowNumber",
      headerName: "Sr#.",
      width: 80,
    },
    {
      field: filterType === 'name' ? null : 'employee_name',
      headerName: "Employee Name",
      width: 230,
      hide: filterType === 'name', 
    },
    {
      field: "employee_phone",
      headerName: "Phone",
      width: 200,
      renderCell: (params) => (
        <div>
          <a href={`https://wa.me/${params.row.employee_phone}`} target="blank">
            <WhatsAppIcon style={{ cursor: 'pointer', ...greenIconStyle }} />
          </a>
          <span style={{ marginLeft: '10px' }}>{params.row.employee_phone}</span>
        </div>
      ),
    },
    
    {
      field: filterType === 'date' ? null : "createdAt",
      headerName: "Date",
      width: 160,
      valueFormatter: (params) => {
        return formatDate(params.value);
      },
      hide: filterType === 'date', 
    },
    {
      field: "checkin_time",
      headerName: "Check In",
      width: 230,
      renderCell: (params) => (
        <input
          style={{
            width: "200px",
            height: "50px",
            fontSize: "15px",
            border: "none",
          }}
          type="time"
          placeholder="Enter Check-In Time"
          value={params.row.checkin_time}
          onChange={(event) => {
            if (params.row.status === "absent" || params.row.status === "leave" || params.row.status === "non paid leave") {
              // Disable input for absent, leave, or non-paid leave
              return;
            }
            updateCheckinTime(params.row._id, event.target.value);
          }}
          disabled={params.row.status === "absent" || params.row.status === "leave" || params.row.status === "non paid leave"}
        />
      ),
    },
    {
      field: "checkout_time",
      headerName: "Check Out",
      width: 230,
      renderCell: (params) => (
        <input
          style={{
            width: "200px",
            height: "50px",
            fontSize: "15px",
            border: "none",
          }}
          type="time"
          placeholder="Enter Check-Out Time"
          value={params.row.checkout_time}
          onChange={(event) => {
            if (params.row.status === "absent" || params.row.status === "leave" || params.row.status === "non paid leave") {
              // Disable input for absent, leave, or non-paid leave
              return;
            }
            updateCheckoutTime(params.row._id, event.target.value);
          }}
          disabled={params.row.status === "absent" || params.row.status === "leave" || params.row.status === "non paid leave"}
        />
      ),
    },
    {
      field: "total_time",
      headerName: "Total Hours",
      width: 130,
      valueGetter: (params) => {
        return calculateTotalTime(
          params.row.checkin_time,
          params.row.checkout_time
        );
      },
    },
    {
      field: "status",
      headerName: (
        <div style={{ whiteSpace: "nowrap" }}>
          P<span style={{ marginLeft: "40px" }}></span>A
          <span style={{ marginLeft: "40px" }}></span>L
          <span style={{ marginLeft: "40px" }}></span>
          HL<span style={{ marginLeft: "20px" }}></span>
          UPL
        </div>
      ),
      width: 300,
      renderCell: (params) => (
        <RadioGroup
          value={params.row.status}
          onChange={(event) => handleStatusChange(event, params.row._id)}
          row
        >
          <FormControlLabel value="present" control={<Radio />} label="" />
          <FormControlLabel value="absent" control={<Radio />} label="" />
          <FormControlLabel value="leave" control={<Radio />} label="" />
          <FormControlLabel value="half leave" control={<Radio />} label="" />
          <FormControlLabel
            value="non paid leave"
            control={<Radio />}
            label=""
          />
        </RadioGroup>
      ),
    },
  ];
  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }

  const [filterButtonClicked, setFilterButtonClicked] = useState(false);
  const [appliedFilterName, setAppliedFilterName] = useState("");
  const [appliedFilterStartDate, setAppliedFilterStartDate] = useState("");
  const [appliedFilterEndDate, setAppliedFilterEndDate] = useState("");
  const applyFilter = async () => {
    console.log("Applying filter");

    setFilterButtonClicked(true);

    let filteredData = [];

    if (filterName && filterStartDate && filterEndDate) {
      // Filter by both name and date
      try {
        const selectedEmployee = employeeList.find(
          (employee) =>
            employee.first_name + " " + employee.last_name === filterName
        );

        if (selectedEmployee) {
          setSelectedEmployeeId(selectedEmployee._id);

          const response = await axios.get(
            `https://si-nexus-back-end.vercel.app/api/attendance/get_attendance/${selectedEmployee._id}/${filterStartDate}/${filterEndDate}`
          );

          filteredData = response.data.response;
          console.log("Filtered Data by Name and Date:", filteredData);
        } else {
          console.log("Employee not found");
        }
      } catch (error) {
        console.log("Error applying filter:", error);
      }
    } else if (filterName) {
      // Filter by name only
      try {
        const selectedEmployee = employeeList.find(
          (employee) =>
            employee.first_name + " " + employee.last_name === filterName
        );

        if (selectedEmployee) {
          setSelectedEmployeeId(selectedEmployee._id);

          const response = await axios.get(
            `https://si-nexus-back-end.vercel.app/api/attendance/get_employee_data/${selectedEmployee._id}`
          );

          filteredData = response.data.response;
          console.log("Filtered Data by Name:", filteredData);
        } else {
          console.log("Employee not found");
        }
      } catch (error) {
        console.log("Error applying filter:", error);
      }
    } else if (filterStartDate && filterEndDate) {
      // Filter by date only
      try {
        const response = await axios.get(
          `https://si-nexus-back-end.vercel.app/api/attendance/get_by/${filterStartDate}`,
          {
            params: {
              endDate: filterEndDate,
            },
          }
        );

        filteredData = response.data.response;
        console.log("Filtered Data by Date:", filteredData);
      } catch (error) {
        console.log("Error applying filter:", error);
      }
    } else {
      
      setAppliedFilterName("");
      setAppliedFilterStartDate("");
      setAppliedFilterEndDate("");
    }
    setAppliedFilterName(filterName);
    setAppliedFilterStartDate(filterStartDate);
    setAppliedFilterEndDate(filterEndDate);

    if (!filterName && !filterStartDate && !filterEndDate) {
      getAttendance();
    }

    setFilteredData(filteredData);
    setShowWinModal(false);
  };
  

  
  useEffect(() => {
   
    fetch('https://si-nexus-back-end.vercel.app/api/employee/get_employees')
      .then(response => response.json())
      .then(employeedata => setEmployeeList(employeedata.employees))
      .catch(error => console.error('Error fetching data:', error));
  }, []);
  
  const WinModal = () => {
    return (
      <div className="popup-overlay">
        <div className="popup">
          <div className="header">
            <h2 style={{ paddingLeft: "30px" }}>Filter</h2>
            <button className="close-button" onClick={() => setShowWinModal(false)}>
              <img src={Close} alt="Close" />
            </button>
          </div>
  
          <div className="select-name">
            <h4>Employee Name</h4>
            <select
              style={{
                width: '29vw',
                height: '6vh',
                border: 'none',
                background: 'rgba(243, 243, 243, 1)',
                borderRadius: '10px',
                marginTop: '10px',
                paddingLeft: '10px',
              }}
              value={filterName}
              onChange={e => setFilterName(e.target.value)}
            >
              <option value="">Select an employee</option>
              {Array.isArray(employeeList) &&
                employeeList.map(employee => (
                  <option key={employee._id} value={employee.first_name + ' ' + employee.last_name}>
                    {employee.first_name + ' ' + employee.last_name}
                  </option>
                ))}
            </select>
          </div>
  
          <div className="select-date">
            <div>
              <h4>From Date</h4>
              <input
                style={{
                  width: "14vw",
                  height: "6vh",
                  border: "none",
                  background: "rgba(243, 243, 243, 1)",
                  borderRadius: "10px",
                  marginTop: "10px",
                  paddingLeft:'10px'
                }}
                type="date"
                value={filterStartDate}
                onChange={(e) => setFilterStartDate(e.target.value)}
              />
            </div>
            <div>
              <h4>To Date</h4>
              <input
                placeholder="Enter Start Date"
                style={{
                  width: "14vw",
                  height: "6vh",
                  border: "none",
                  background: "rgba(243, 243, 243, 1)",
                  borderRadius: "10px",
                  marginTop: "10px",
                  paddingLeft:'10px',
                  marginRight:'20px'
                }}
                type="date"
                value={filterEndDate}
                onChange={(e) => setFilterEndDate(e.target.value)}
              />
            </div>
          </div>
  
          <div className="filter-btn">
            <button className="modal-btn" onClick={() => setShowWinModal(false)}>
              Cancel
            </button>
            <button className="modal-btn-2" onClick={applyFilter}>
              Filter
            </button>
          </div>
        </div>
      </div>
    );
  };
  const toggleWinModal = () => {
    setShowWinModal(!showWinModal);
  };
  const fetchSingleDateData = async (selectedDate) => {
    console.log("Fetching data for the selected date:", selectedDate);
  
    try {
      const response = await axios.get(
        `https://si-nexus-back-end.vercel.app/api/attendance/get_by_single/${selectedDate}`
      );
  
      setFilteredData(response.data.data);
  
      console.log("Filtered Data by Single Date:", response.data.data);
    } catch (error) {
      console.log("Error fetching data for the selected date:", error);
    }
  };
  
 


  const exportDataToCSV = () => {
    console.log("Export button clicked");
    const dataToExport = filteredData.length > 0 ? filteredData : filteredData;
    console.log("Data to export:", dataToExport);
  
    const columnMapping = {
      "Employee Name": "employee_name",
      Date: "createdAt",
      "Check In": "checkin_time",
      "Check Out": "checkout_time",
      Status: "status",
    };
  
  
    const mappedData = dataToExport.map((item, index) => {
      const mappedItem = {};
      mappedItem["Sr#"] = index + 1; 
      for (const header in columnMapping) {
        const dataKey = columnMapping[header];
        if (header === "Date") {
          mappedItem[header] = formatDate(item[dataKey]);
        } else if (header === "Check Out") {
          mappedItem[header] = item[dataKey];
          mappedItem["Total Time"] = calculateTotalTime(
            item["checkin_time"],
            item[dataKey]
          ); 
        } else {
          mappedItem[header] = item[dataKey];
        }
      }
      return mappedItem;
    });
  
    
    const rearrangedHeaders = [
      "Sr#",
      "Employee Name",
      "Date",
      "Check In",
      "Check Out",
      "Total Time",
      "Status",
    ];
  
    const rearrangedMappedData = mappedData.map((item) => {
      const rearrangedItem = {};
      for (const header of rearrangedHeaders) {
        rearrangedItem[header] = item[header];
      }
      return rearrangedItem;
    });
  
    const options = {
      filename: "attendance_data.csv",
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      headers: rearrangedHeaders,
      title: "Attendance Data",
      useHeader: true,
    };
    console.log("export:", options);
    const csvExporter = new ExportToCsv(options);
    console.log("export file:", csvExporter);
    csvExporter.generateCsv(rearrangedMappedData);
  };
  
  
  console.log(ExportToCsv);
 
 
  const resetFiltersAndFetchOriginalData = async () => {
    setFilterName('');
    setFilterStartDate('');
    setFilterEndDate('');
    await getAttendance();
  };
  
 
  return (
    <div className="list">
  <Sidebar />
  <div className="list-container">
    <Navbar header={"Attendance"} />
    <ListHeader
      header={"Dashboard"}
      header1={"Attendance"}
      firstLinks={"/"}
      secondLinks={"/attendance"}
      forthButton={toggleWinModal}
      fifthButton={exportDataToCSV}
      showDateInput={true}
      currentDate={formatDate(currentDate)}
      setCurrentDate={setCurrentDate}
      fetchSingleDateData={fetchSingleDateData}
    />
    <div className="filter-values">
 {filterButtonClicked && (
<div className="filter-values">
  {filterName && filterStartDate && filterEndDate ? (
    <div className="filter-value">
      <span style={{ color: 'rgba(45, 116, 255, 1)', paddingLeft: '35px' ,fontSize:'18px',paddingTop:'15px'}}>
       {filterName}, ({filterStartDate} - {filterEndDate})
       </span>
      <IconButton
        onClick={() => resetFiltersAndFetchOriginalData()} 
        size="small"
        color="rgba(45, 116, 255, 1)"
      >
        <CloseIcon />
      </IconButton>
    </div>
  ) : (
    <>
      {filterName && (
  <div className="filter-value">
    {appliedFilterName && (
      <span style={{ color: 'rgba(45, 116, 255, 1)', paddingLeft: '35px' ,fontSize:'18px',paddingTop:'15px',fontWeight:'500'}}>
        Employee Name: {appliedFilterName}
      </span>
    )}
    <IconButton
      onClick={() => resetFiltersAndFetchOriginalData()} 
      size="small"
      color="rgba(45, 116, 255, 1)"
    >
      <CloseIcon />
    </IconButton>
  </div>
)}

      {filterStartDate && filterEndDate && (
        <div className="filter-value">
           <span style={{ color: 'rgba(45, 116, 255, 1)', paddingLeft: '35px' ,fontSize:'18px',paddingTop:'15px'}}>
           ({filterStartDate} - {filterEndDate})
           </span>
          <IconButton
            onClick={() => resetFiltersAndFetchOriginalData()} 
            size="small"
            color="rgba(45, 116, 255, 1)"
          >
            <CloseIcon />
          </IconButton>
        </div>
      )}
    </>
  )}
</div>
)}
</div>

    <div
      style={{
        height: "700px",
        width: "calc(100vw - 330px)",
        overflow: "auto",
        background: "#ffffff",
        margin: "20px 30px",
        borderRadius: "10px",
      }}
    >
      {filteredData.length === 0 ? (
        <p style={{ paddingLeft: '30vw', paddingTop: '20vh' }}>data not found</p>
      ) : (
        <DataGrid
          rows={filteredData.map((row, index) => ({
            ...row,
            rowNumber: index + 1,
          }))}
          columns={employeeColumns}
          loading={!filteredData.length}
          getRowId={(row) => row._id}
        />
      )}
    </div>
    {showWinModal && (
      <WinModal
        filterType={filterType}
        setFilterType={setFilterType}
        filterName={filterName}
        setFilterName={setFilterName}
        filterStartDate={filterStartDate}
        setFilterStartDate={setFilterStartDate}
        filterEndDate={filterEndDate}
        setFilterEndDate={setFilterEndDate}
        applyFilter={applyFilter}
        setShowWinModal={setShowWinModal}
      />
    )}
  </div>
</div>

  );
}
